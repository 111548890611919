import { BrandConfig } from '@headless-workspace/config';
import { FlagValue, mapFlags, mapMedia, MediaValue, ProductType } from '@headless-workspace/domain/common/client';
import {
    HTMLString,
    mapProductBrandData,
    mapProductPriceData,
    ProductBrandData,
    ProductPriceData,
    PromotionType,
    VipBrandsValue,
} from '@headless-workspace/domain/common/server';
import { ArrayHelpers, ValueHelpers } from '@headless-workspace/utils';
import { CapacityFormat } from '../../../../src';
import { ProductDetailDTO } from './ProductDetailDTO';
import { mapProductDetailVariant, ProductDetailVariantValue } from './ProductDetailVariantValue';
import { mapProductInfo, ProductInfoTabParamsValue } from './ProductInfoTabParamsValue';
import { mapProductDetailBanners, ProductDetailBannersValue } from './banner/ProductDetailBannerValue';
import { BreadcrumbValue } from './breadcrumb';

export type ProductDetailValue = ProductPriceData &
    ProductBrandData & {
        id: string;
        sku: string;
        name: {
            full: string;
            partOne: string;
            partTwo?: string;
        };
        vat?: number;
        rating: number;
        // FIXME : optional totalIndividualPrice will be challenged with the SODA connected version and FHEAD-196
        totalIndividualPrice?: number;
        reviewCount: number;
        stockLevel: number;
        orderable?: boolean;
        variants: ProductDetailVariantValue[];
        medias: MediaValue[];
        flags?: FlagValue[];
        breadcrumb: BreadcrumbValue;
        productInfoTabParams: ProductInfoTabParamsValue[];
        pricePerUnit?: string;
        isSocialProof: boolean;
        buyNowPayLaterContent: HTMLString;
        type: ProductType;
        formats: CapacityFormat[];
        promotion?: PromotionType;
        banners?: ProductDetailBannersValue;
        isRedDot?: boolean;
    };

const isProductNameOnTwoLines = (brandId?: string): boolean => {
    if (!brandId) {
        return false;
    }

    return BrandConfig.productNameOnTwoLines.includes(brandId);
};

// FIXME : Will be removed with the SODA connected version and FHEAD-196
const mockedValuePrice = 1000;

// FIXME: buyNowPayLaterContent var to be removed when unmock => FHEAD-794
const buyNowPayLaterContent = `
    <p>
        Payer en 3x ou 4x avec
        <a class='text-underline' href='https://faq.sephora.fr/s/'>Paypal</a>,
        <a class='text-underline' href='https://faq.sephora.fr/s/'>Oney</a> ou
        <a class='text-underline' href='https://faq.sephora.fr/s/'>Klarna</a>
    </p>`;

export const mapProductDetail = (dto: ProductDetailDTO, vipBrands: VipBrandsValue): ProductDetailValue => {
    const [namePartOne, ...namePartTwo] = isProductNameOnTwoLines(dto.brand.id) ? dto.name.split(' - ') : [dto.name];
    const variants = dto.variants.map((variant) => mapProductDetailVariant(variant, dto.currency));

    return {
        id: dto.id,
        sku: variants.length > 0 ? variants[0].id : dto.id, // FIXME: should use defaultVariantId when API is ready
        name: {
            full: dto.name,
            partOne: namePartOne,
            partTwo: ArrayHelpers.isNotEmpty(namePartTwo) ? namePartTwo.join(' - ') : undefined,
        },
        productInfoTabParams: mapProductInfo(dto),
        vat: ValueHelpers.mapOptional(dto.vat),
        rating: dto.rating,
        totalIndividualPrice: dto.valuePrice ?? mockedValuePrice, // FIXME : mockedValuePrice to be removed when unmock FHEAD-196
        reviewCount: dto.reviewCount,
        stockLevel: dto.stockLevel ?? 0,
        orderable: ValueHelpers.mapOptional(dto.orderable),
        variants,
        medias: dto.medias.map(mapMedia),
        flags: mapFlags(dto.flags),
        ...mapProductBrandData(dto, vipBrands),
        ...mapProductPriceData(dto, dto.currency),
        breadcrumb: dto.breadcrumb,
        pricePerUnit: dto.pricePerUnit,
        isSocialProof: true, // FIXME: unmock SocialProof
        buyNowPayLaterContent: HTMLString(buyNowPayLaterContent), // FIXME: to be adjusted when unmock => FHEAD-794
        promotion: dto.promotion ? PromotionType[dto.promotion] : undefined,
        type: ProductType[dto.type],
        formats: Array.from(new Set(variants.map((variant) => variant.format))).sort(),
        banners: mapProductDetailBanners(dto.banners), // FIXME: to be adjusted when unmock
        isRedDot: dto.isRedDot,
    };
};

'use client';

import { useCallback, useContext } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { Position, PRODUCT_DETAIL_VISUAL_MODAL_ID } from '@headless-workspace/config';
import { SideMenuModal, UIBrandBanner, UIContext, useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowBreadcrumb, PropsWithAriaLabel, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ArrayHelpers } from '@headless-workspace/utils';
import { ProductDetailValue, PromotionInformationType } from '../../../server';
import {
    ProductDetailInformation,
    ProductDetailVisual,
    ProductDetailVisualModal,
    ProductDetailVisualWithoutMedia,
} from '../productDetail';

type ProductDetailContainerSectionProps = PropsWithAriaLabel &
    PropsWithFontFamily &
    PropsWithLocale & {
        product: ProductDetailValue;
        vipBrands: VipBrandsValue;
        promotionInformation?: PromotionInformationType;
        redDotText?: string;
    };

export const ProductDetailContainerSection: Component<ProductDetailContainerSectionProps> = ({
    product,
    locale,
    vipBrands,
    promotionInformation,
    fontFamily,
    redDotText,
}) => {
    const t = useTranslations('Common.breadcrumb');
    const isMobile = useTailwindBreakpoint('tablet', false, true);

    const {
        menuModal: { addAndOpen: addAndOpenModal, isOpen: isModalOpen },
    } = useContext(UIContext);

    const onProductVisualClickOpenModal = useCallback(
        (index: number) => {
            const ProductVisualModal = <ProductDetailVisualModal medias={product.medias} activeIndex={index} />;
            const productDetailModalClassName = 'flex flex-col w-full overflow-y-scroll bg-background-l0';
            addAndOpenModal(
                PRODUCT_DETAIL_VISUAL_MODAL_ID,
                SideMenuModal(Position.None, { default: ProductVisualModal }, productDetailModalClassName),
            );
        },
        [addAndOpenModal, product.medias],
    );

    const onProductDetailVisualClick = useCallback(
        (index: number) => {
            if (!isModalOpen) {
                onProductVisualClickOpenModal(index);
            }
        },
        [isModalOpen, onProductVisualClickOpenModal],
    );

    return (
        <section id={'product-detail-container'} className={'flex flex-col'}>
            {product.hasProductDetailLogoBanner && (
                <UIBrandBanner
                    className={clsx(product.hasFullWidthLogoBanner && 'w-fullViewport self-center')}
                    logo={product.brand.logo}
                    redirectionURL={product.brand.url}
                    hasWhiteLogoBackground={product.hasWhiteLogoBackground}
                    logoPosition={product.brandLogoPosition}
                />
            )}
            <div className={'px-1 py-0.75'}>
                <GlowBreadcrumb
                    breadcrumb={product.breadcrumb}
                    ariaLabel={t('ariaLabel.name')}
                    seeAllAriaLabel={t('ariaLabel.seeAll')}
                    isMobile={isMobile}
                />
            </div>
            <article
                className={
                    'flex flex-col desktopS:flex-row py-0.75 desktopS:px-1.5 gap-1.5 tablet:gap-3 desktopS:gap-1.5'
                }
            >
                {ArrayHelpers.isNotEmpty(product.medias) ? (
                    <ProductDetailVisual
                        onProductDetailVisualClick={onProductDetailVisualClick}
                        medias={product.medias}
                        flags={product.flags}
                        isSocialProof={product.isSocialProof}
                    />
                ) : (
                    <ProductDetailVisualWithoutMedia />
                )}
                <div className={'flex-1 desktopS:max-w-productInfosDesktopS desktop:max-w-productInfosDesktop'}>
                    <ProductDetailInformation
                        promotionInformation={promotionInformation}
                        product={product}
                        locale={locale}
                        vipBrands={vipBrands}
                        fontFamily={fontFamily}
                        redDotText={redDotText}
                    />
                </div>
            </article>
        </section>
    );
};

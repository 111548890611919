'use client';

import { useCallback } from 'react';
import Image from 'next/image';
import { Position, PRODUCT_INFO_QUICK_VIEW_MENU_ID, WISHLIST_MODAL_ID } from '@headless-workspace/config';
import { SideMenuModal, UICarouselContainer, useTailwindBreakpoint, useUIContext } from '@headless-workspace/core-ui';
import { useWishlist, WishlistModal } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowProductTile, ModalCommonClasses, PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component, ProductType } from '@headless-workspace/typings';
import { QuickViewProductMenu, TileProductValue, useProductTilesMapper } from '../../../../src';

type ProductCarouselProps = PropsWithClassName &
    PropsWithLocale & {
        products: TileProductValue[];
        vipBrands: VipBrandsValue;
        prevBtnAriaLabel: string;
        nextBtnAriaLabel: string;
        wishlistBtnAriaLabel: string;
        thumbArialLabel: string;
        navigationButtonClassName?: string;
        type?: 'beautyTips';
        showStockStatusBtn?: boolean;
        enablePriceExceptionLegalMentions?: boolean;
        isAQuickView?: boolean;
    };

export const ProductCarousel: Component<ProductCarouselProps> = ({
    className,
    products,
    vipBrands,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    wishlistBtnAriaLabel,
    thumbArialLabel,
    navigationButtonClassName,
    locale,
    type,
    showStockStatusBtn = false,
    enablePriceExceptionLegalMentions,
    isAQuickView = false,
}) => {
    const isTablet = useTailwindBreakpoint('tablet');
    const {
        menuModal: { addIfAbsentAndOpen },
    } = useUIContext();

    const { shouldAddToWishlist, update: updateWishlist, isProductInWishlist } = useWishlist();
    const productTiles = useProductTilesMapper(products, locale, showStockStatusBtn, enablePriceExceptionLegalMentions);

    const openQuickViewMenuHandler = useCallback(
        (productId: string, productSku: string) => () => {
            const productDetailInfosMenuClassName = 'flex flex-col overflow-hidden bg-background-l0';
            addIfAbsentAndOpen(
                PRODUCT_INFO_QUICK_VIEW_MENU_ID,
                SideMenuModal(
                    Position.Right,
                    {
                        default: (
                            <QuickViewProductMenu
                                productId={productId}
                                productSku={productSku}
                                vipBrands={vipBrands}
                                locale={locale}
                            />
                        ),
                    },
                    productDetailInfosMenuClassName,
                ),
            );
        },
        [addIfAbsentAndOpen],
    );

    return (
        <UICarouselContainer
            className={className}
            items={productTiles}
            renderItem={(product, isInView) => {
                const buttonOnClick =
                    isAQuickView && product.type !== ProductType.MONO_SKU
                        ? openQuickViewMenuHandler(product.id, product.sku)
                        : undefined;
                return (
                    <GlowProductTile
                        {...product}
                        button={{
                            ...product.button,
                            onClick: buttonOnClick,
                        }}
                        isTablet={isTablet}
                        isLoading={!isInView}
                        ImageTagName={Image}
                        wishlist={{
                            onClick: () => {
                                if (shouldAddToWishlist) {
                                    updateWishlist({
                                        productId: product.id,
                                        productSku: product.sku,
                                        productName: product.name,
                                    });
                                } else {
                                    addIfAbsentAndOpen(
                                        WISHLIST_MODAL_ID,
                                        SideMenuModal(
                                            Position.None,
                                            {
                                                default: <WishlistModal productSku={product.sku} locale={locale} />,
                                            },
                                            ModalCommonClasses,
                                        ),
                                    );
                                }
                            },
                            isAdded: isProductInWishlist(product.sku),
                            ariaLabel: wishlistBtnAriaLabel,
                        }}
                        legalMentionsLabel={product.legalMentionsLabel}
                    />
                );
            }}
            prevBtnAriaLabel={prevBtnAriaLabel}
            nextBtnAriaLabel={nextBtnAriaLabel}
            thumbArialLabel={thumbArialLabel}
            navigationButtonClassName={navigationButtonClassName}
            type={type}
        />
    );
};

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { AsyncDataWithoutResult, unwrapResultSuccess } from '@headless-workspace/core-domain';
import { StockStatus, useFavoriteStoreInfo } from '@headless-workspace/domain/common/client';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { DateHelpers } from '@headless-workspace/utils';
import {
    addBusinessDays,
    ProductDetailDeliveryInfoValue,
    useHolidays,
    useIsNotifyMeEnabled,
    useProductDeliveryInfo,
} from '../../../../../src';

type DeliveryZoneBusinessRulesParams = PropsWithLocale & {
    productDeliveryInfoData: AsyncDataWithoutResult<ProductDetailDeliveryInfoValue>;
    isNotifyMeEnabledData: AsyncDataWithoutResult<boolean>;
    stock: number;
    sku: string;
};

export type UseProductDeliveryBusinessRulesReturnType = {
    shipping: DeliveryInformation;
    store: DeliveryInformation;
    productDeliveryInfoData: AsyncDataWithoutResult<ProductDetailDeliveryInfoValue>;
    isNotifyMeEnabledData: AsyncDataWithoutResult<boolean>;
};

type DeliveryInformation = AsyncDataWithoutResult<{
    description?: string;
    statusLabel: string;
    status?: StockStatus;
    ctaType?: CtaType;
}>;

export enum CtaType {
    SelectStore = 'SelectStore',
    ChangeStore = 'ChangeStore',
    NotifyMe = 'NotifyMe',
}

type UseProductDeliveryBusinessRulesParams = PropsWithLocale & {
    stock: number;
    sku: string;
};

export const useProductDeliveryBusinessRules = ({
    locale,
    stock,
    sku,
}: UseProductDeliveryBusinessRulesParams): UseProductDeliveryBusinessRulesReturnType => {
    const { isLoading: isProductDeliveryInfoLoading, result: productDeliveryInfoResult } = useProductDeliveryInfo(sku);
    const { isLoading: isNotifyMeEnabledLoading, result: isNotifyMeEnabledResult } = useIsNotifyMeEnabled();

    const productDeliveryInfoData = useMemo(
        () => ({
            isLoading: isProductDeliveryInfoLoading,
            data: unwrapResultSuccess(productDeliveryInfoResult),
        }),
        [isProductDeliveryInfoLoading, productDeliveryInfoResult],
    );

    const isNotifyMeEnabledData = useMemo(
        () => ({
            isLoading: isNotifyMeEnabledLoading,
            data: unwrapResultSuccess(isNotifyMeEnabledResult),
        }),
        [isNotifyMeEnabledLoading, isNotifyMeEnabledResult],
    );

    const shipping = useShippingInformationBusinessRules({
        locale,
        productDeliveryInfoData,
        isNotifyMeEnabledData,
        sku,
    });

    const store = useStoreInformationBusinessRules({
        productDeliveryInfoData,
        isNotifyMeEnabledData,
        stock,
        sku,
    });

    return {
        shipping,
        store,
        productDeliveryInfoData,
        isNotifyMeEnabledData,
    };
};

const BUSINESS_DAYS_TO_SHIP = 4;

const useShippingInformationBusinessRules = ({
    productDeliveryInfoData,
    isNotifyMeEnabledData,
    locale,
}: Omit<DeliveryZoneBusinessRulesParams, 'stock'>): DeliveryInformation => {
    const t = useTranslations('Discover.Pdp.deliveryZone');
    const shippingInformation = productDeliveryInfoData.data?.shipping;

    const { isLoading: isHolidaysLoading, result: holidaysResult } = useHolidays();
    const holidays = unwrapResultSuccess(holidaysResult);

    const formattedDate = DateHelpers.formatDateToDayMonth(
        locale,
        addBusinessDays(new Date(), BUSINESS_DAYS_TO_SHIP, holidays),
    );
    const stockStatus = shippingInformation?.stockStatus;
    const isAvailable = stockStatus === StockStatus.Available;

    const statusLabel = useMemo(() => {
        switch (stockStatus) {
            case StockStatus.Available:
                return t('label.available');
            case StockStatus.AvailableOnline:
                return t('label.availableOnline');
            case StockStatus.Unavailable:
                return t('label.unavailable');
            case StockStatus.OutOfStock:
                return t('label.outOfStock');
            case StockStatus.AvailableOtherStore:
            default:
                return '';
        }
    }, [stockStatus, t]);

    const ctaType: CtaType | undefined = useMemo(() => {
        if (stockStatus === StockStatus.OutOfStock && isNotifyMeEnabledData.data) {
            return CtaType.NotifyMe;
        }

        return undefined;
    }, [stockStatus, isNotifyMeEnabledData]);

    return {
        isLoading: productDeliveryInfoData.isLoading || isNotifyMeEnabledData.isLoading || isHolidaysLoading,
        data:
            !productDeliveryInfoData.data || !holidays
                ? undefined
                : {
                      description: isAvailable ? t('label.shippingFrom', { date: formattedDate }) : undefined,
                      statusLabel,
                      status: stockStatus,
                      ctaType,
                  },
    };
};

const LOW_STOCK_THRESHOLD = 10;

const useStoreInformationBusinessRules = ({
    productDeliveryInfoData,
    isNotifyMeEnabledData,
    stock,
}: Omit<DeliveryZoneBusinessRulesParams, 'locale'>): DeliveryInformation => {
    const t = useTranslations('Discover.Pdp.deliveryZone');
    const storeInformation = productDeliveryInfoData.data?.currentStore;

    // FIXME: should be removed if API return store name
    const { result: favoriteStoreResult } = useFavoriteStoreInfo();
    const storeName = unwrapResultSuccess(favoriteStoreResult)?.name;

    const stockStatus = storeInformation?.stockStatus;
    const isAvailable = stockStatus === StockStatus.Available;

    const availableLabel = storeName
        ? stock <= LOW_STOCK_THRESHOLD
            ? t('label.availableLowStockInSelectedStore', { storeName, stock })
            : t('label.availableInSelectedStore', { storeName })
        : t('label.available');

    const statusLabelMap = {
        [StockStatus.Available]: availableLabel,
        [StockStatus.Unavailable]: t('label.unavailable'),
        [StockStatus.AvailableOtherStore]: t('label.unavailableInSelectedStore', { storeName }),
        [StockStatus.AvailableOnline]: t('label.availableOnline'),
        [StockStatus.OutOfStock]: t('label.outOfStock'),
    };

    const ctaType: CtaType | undefined = useMemo(() => {
        if (isAvailable) {
            return CtaType.SelectStore;
        }

        if (stockStatus === StockStatus.AvailableOtherStore) {
            return CtaType.ChangeStore;
        }
        if (stockStatus === StockStatus.OutOfStock && isNotifyMeEnabledData.data) {
            return CtaType.NotifyMe;
        }
        return undefined;
    }, [isAvailable, stockStatus, isNotifyMeEnabledData.data]);

    const hasDescription = (isAvailable || stockStatus === StockStatus.OutOfStock) && !storeName;

    return {
        isLoading: productDeliveryInfoData.isLoading || isNotifyMeEnabledData.isLoading,
        data: !productDeliveryInfoData.data
            ? undefined
            : {
                  description: hasDescription ? t('label.selectStoreToDiscoverStock') : undefined,
                  statusLabel: stockStatus ? statusLabelMap[stockStatus] : '',
                  status: stockStatus,
                  ctaType,
              },
    };
};

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { PromotionType } from '@headless-workspace/domain/common/server';
import { OmnibusInformationsValue, PromoStandardTextValue, getPromotionInfoServerAction } from '../../../../server';
import { ProductDetailsErros } from './ProductDetailsErros';

export const usePromotionInfo = (
    promotion?: PromotionType,
): AsyncData<OmnibusInformationsValue | PromoStandardTextValue> => {
    const t = useTranslations('Discover.Pdp.promotionInfo.error');

    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.Promotion,
        ServerActionName.getPromotionInfoServerAction,
        () => getPromotionInfoServerAction(promotion),
    );

    if (error) {
        return {
            isLoading,
            result: ResultFailure(ProductDetailsErros.Promotion, error instanceof Error ? error.message : t('default')),
        };
    }

    if (!data) {
        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { ProductType } from '@headless-workspace/domain/common/client';
import { ChipColor } from '../../../client';

export const useProductDetailsUtils = (type?: ProductType) => {
    const t = useTranslations('Discover.Pdp.information.selector');

    const productSelectionTitle = useMemo(() => {
        if (!type) {
            return '';
        }

        const titleMap: Partial<Record<ProductType, string>> = {
            [ProductType.MULTI_SKU_SIZE]: t('modal.label.selectMultiSkuSizeTitle'),
            [ProductType.MULTI_SKU_SHADE_UNI]: t('modal.label.selectMultiSkuShadeTitle'),
            [ProductType.MULTI_SKU_SHADE_DIFF]: t('modal.label.selectMultiSkuShadeTitle'),
        };

        return titleMap[type] ?? '';

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const getStockInformation = (isAvailable: boolean): { label: string; chipColor: ChipColor } => {
        return {
            label: t(`modal.label.${isAvailable ? 'available' : 'unavailable'}`),
            chipColor: isAvailable ? ChipColor.Success : ChipColor.Error,
        };
    };

    return { productSelectionTitle, getStockInformation };
};

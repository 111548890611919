'use client';

import { useEffect, useMemo, useState } from 'react';
import {
    PICK_NOT_SELECTED_URL,
    PICK_SELECTED_URL,
    WOOSMAP_BASE_URL,
    WOOSMAP_DEFAULT_HEIGHT,
    WOOSMAP_DEFAULT_STYLE,
    WOOSMAP_DEFAULT_WIDTH,
    WOOSMAP_DEFAULT_ZOOM,
    WOOSMAP_ID,
} from '@headless-workspace/config';
import { UIStoreInfoCard } from '@headless-workspace/core-ui';
import { ClientEnvKey, useClientData } from '@headless-workspace/domain/common/client';
import { StoreInfo } from '@headless-workspace/domain/common/server';
import { Component } from '@headless-workspace/typings';
import { useScript } from 'usehooks-ts';
import { useStoreInfoCardUtils } from '../../../hooks';

type StoreSelectionMenuMapProps = {
    stores: StoreInfo[];
    favoriteStoreId?: string;
    highlightedStoreId?: string;
    setHighlightedStore: (store: StoreInfo) => void;
    currentUserPosition: GeolocationPosition | null;
};

export const StoreSelectionMenuMap: Component<StoreSelectionMenuMapProps> = ({
    stores,
    favoriteStoreId,
    highlightedStoreId,
    setHighlightedStore,
    currentUserPosition,
}) => {
    const { mapStoreInfoToUIStoreInfoCardStore } = useStoreInfoCardUtils();

    const [selectedMarker, setSelectedMarker] = useState<woosmap.map.Marker | undefined>();
    const { getClientEnvVariable } = useClientData();

    const woosmapStatus = useScript(`${WOOSMAP_BASE_URL}${getClientEnvVariable(ClientEnvKey.WoosmapKey)}`, {
        removeOnUnmount: true,
        id: WOOSMAP_ID,
    });

    const highlightedStore = useMemo(() => {
        return stores.find((store) => store.id === highlightedStoreId);
    }, [highlightedStoreId, stores]);

    useEffect(() => {
        if (woosmapStatus === 'ready') {
            const style: woosmap.map.Style = WOOSMAP_DEFAULT_STYLE;
            const coords = stores.map((store) => ({
                lat: store.latitude,
                lng: store.longitude,
            }));

            let center: woosmap.map.LatLngLiteral | undefined = undefined;
            if (currentUserPosition) {
                center = { lat: currentUserPosition.coords.latitude, lng: currentUserPosition.coords.longitude };
            } else {
                if (coords.length > 0) {
                    center = coords[0];
                }
            }

            const map = new woosmap.map.Map(document.getElementById(WOOSMAP_ID) as HTMLElement, {
                zoom: WOOSMAP_DEFAULT_ZOOM,
                center,
            });

            stores.forEach((store) => {
                const marker = new woosmap.map.Marker({
                    position: {
                        lat: store.latitude,
                        lng: store.longitude,
                    },
                    icon: {
                        url: store.id === highlightedStoreId ? PICK_SELECTED_URL : PICK_NOT_SELECTED_URL,
                        scaledSize: {
                            height: WOOSMAP_DEFAULT_HEIGHT,
                            width: WOOSMAP_DEFAULT_WIDTH,
                        },
                    },
                });
                marker.setMap(map);
                marker.addListener('click', function () {
                    // TODO handle click events
                    // if (storeInfo) {
                    //     storeInfo.setIcon(PICK_NOT_SELECTED_URL);
                    // }
                    // marker.setIcon(PICK_SELECTED_URL);
                    // setStoreInfo(marker);
                    // onChange(marker.position.toString())
                    // const infoElement = document.getElementById('info') as HTMLElement;
                    // infoElement.innerHTML = `<strong>${store.city}</strong><span>: ${store.postalCode}</span>`;
                });
                if (highlightedStoreId === store.id && !selectedMarker) {
                    setSelectedMarker(marker);
                }
            });

            const storesOverlay = new woosmap.map.StoresOverlay(style);
            storesOverlay.setMap(map);

            return () => woosmap.map.event.clearInstanceListeners(map);
        }
    }, [currentUserPosition, woosmapStatus, stores, selectedMarker, highlightedStoreId]);

    return (
        <section className={'relative flex flex-1 self-stretch'}>
            <div id={WOOSMAP_ID} className={'flex flex-1 self-stretch'} />
            <div className={'absolute left-1 right-1 bottom-1 bg-surface-primary'}>
                {highlightedStore && <UIStoreInfoCard store={mapStoreInfoToUIStoreInfoCardStore(highlightedStore)} />}
            </div>
        </section>
    );
};

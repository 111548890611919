import { AsyncComponent } from '@headless-workspace/domain/common/server';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { ProductListContainerSection } from './ProductListContainerSection';
import { SeoBottomSection } from './seoBottom';

type ProductListTemplateProps = PropsWithLocale & {
    categoryId: string;
};

export const ProductListTemplate: AsyncComponent<ProductListTemplateProps> = async (categoryId) => {
    return (
        <section id={'product-list-template'} className={'flex flex-col'} data-category-id={categoryId}>
            <ProductListContainerSection />
            <SeoBottomSection />
        </section>
    );
};

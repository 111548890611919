import React from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { isVideo, MediaValue } from '@headless-workspace/domain/common/client';
import { GlowClickable, GlowIcon, GlowImage, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export type ProductMediaPreviewsDesktopProps = {
    medias: MediaValue[];
    selectedVisualIndex: number;
    onSelectVisual: (index: number) => void;
    onProductVisualClick: () => void;
};

const PRODUCT_PREVIEW_SIZE_DESKTOP = 48;
const PRODUCT_PREVIEW_SIZE_DESKTOPS = 40;

export const ProductMediaPreviewsDesktop: Component<ProductMediaPreviewsDesktopProps> = React.memo(
    ({ medias, selectedVisualIndex, onSelectVisual, onProductVisualClick }) => {
        const isDesktop = useTailwindBreakpoint('desktop');
        const PREVIEW_SIZE = isDesktop ? PRODUCT_PREVIEW_SIZE_DESKTOP : PRODUCT_PREVIEW_SIZE_DESKTOPS;

        const handleHoverPreview = (index: number) => () => {
            onSelectVisual(index);
        };

        return medias.map((media, index) => (
            <GlowClickable
                key={`preview-image-${index}`}
                onMouseEnter={handleHoverPreview(index)}
                onClick={onProductVisualClick}
                className={
                    'w-productPreviewDesktopS desktop:w-productPreviewDesktop aspect-1/1 relative flex items-center justify-center cursor-pointer'
                }
                content={{
                    icon: (
                        <>
                            <GlowImage
                                TagName={Image}
                                width={PREVIEW_SIZE}
                                height={PREVIEW_SIZE}
                                image={media}
                                className={clsx(
                                    'flex items-center justify-center',
                                    'w-productPreviewDesktopS desktop:w-productPreviewDesktop aspect-1/1',
                                    'rounded-0.25',
                                    'outline outline-solid',
                                    selectedVisualIndex === index
                                        ? 'opacity-100 outline-bold outline-border-selected'
                                        : 'outline-medium outline-border-primary opacity-65',
                                )}
                                imgClassName={clsx(
                                    'aspect-1/1 ease-in duration-moderate3 object-cover',
                                    selectedVisualIndex === index
                                        ? 'w-productPreviewAnimatedDesktopS desktop:w-productPreviewDesktopS rounded-0.125'
                                        : 'w-productPreviewDesktopS desktop:w-productPreviewDesktop rounded-0.25',
                                )}
                            />
                            {isVideo(media) && (
                                <span
                                    className={clsx(
                                        'absolute w-full aspect-1/1 rounded-0.25',
                                        'flex items-center justify-center',
                                        'ease-in duration-moderate3',
                                        selectedVisualIndex === index ? '' : 'bg-background-overlay',
                                    )}
                                >
                                    <GlowIcon
                                        Icon={Icons.Play}
                                        type={'small'}
                                        className={'text-text-primary-inverse'}
                                    />
                                </span>
                            )}
                        </>
                    ),
                }}
            />
        ));
    },
    (prevProps, nextProps) => prevProps.selectedVisualIndex === nextProps.selectedVisualIndex,
);

'use client';

import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { DataTestConfig } from '@headless-workspace/config';
import { AsyncDataWithoutResult } from '@headless-workspace/core-domain';
import { BACK_TO_TOP_ID, UIRedDotInformation, useTailwindBreakpoint } from '@headless-workspace/core-ui';
import { ProductType } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    GlowPrice,
    GlowPriceProps,
    GlowSkeleton,
    GlowTextBodySmall,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import {
    CapacityFormat,
    ProductDetailVariantValue,
    ProductDetailDeliveryInfoValue,
    useProductDeliveryBusinessRules,
} from '../../../../../../src';
import { SelectorAddToBasketButton } from './SelectorAddToBasketButton';
import { SelectorWishlistButton } from './SelectorWishlistButton';
import { VariantSelector, VariantSelectorZone } from './VariantSelector';

export type ProductDetailSelectorContainerProps = GlowPriceProps &
    PropsWithLocale & {
        id: string;
        sku: string;
        productName: string;
        brandName: string;
        vipBrands: VipBrandsValue;
        type: ProductType;
        formats: CapacityFormat[];
        variants: ProductDetailVariantValue[];
        currency: string;
        stock: number;
        squaredShade: boolean;
        isRedDot?: boolean;
        redDotText?: string;
    };

const IMAGE_WIDTH = 40;
const IMAGE_HEIGHT = 40;
const BACK_TO_TOP_OFFSET = 16;

const STATIC_CTA_ID = 'add-to-basket-cta';
const FIXED_ELEMENT_ID = 'add-to-basket-fixed';
const CONTAINER_FIXED_ELEMENT_ID = 'fixed-container';

// TODO: Plug current variant thumbnail instead of skeleton when API return variant image
export const ProductDetailSelectorContainer: Component<ProductDetailSelectorContainerProps> = ({
    id,
    sku,
    productName,
    brandName,
    locale,
    vipBrands,
    fontFamily,
    type,
    formats,
    variants,
    currency,
    squaredShade,
    isRedDot,
    redDotText,
    stock,
    ...priceProps
}) => {
    const isMinTablet = useTailwindBreakpoint('tablet');
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const { productDeliveryInfoData, isNotifyMeEnabledData } = useProductDeliveryBusinessRules({
        locale,
        stock,
        sku: id,
    });

    // Observe the CTA to show the fixed bar
    useEffect(() => {
        const addToBasketCtaElement = document.getElementById(STATIC_CTA_ID);

        const addToBasketCtaObserver = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        });

        if (addToBasketCtaElement) {
            addToBasketCtaObserver.observe(addToBasketCtaElement);
        }

        return () => {
            addToBasketCtaObserver.disconnect();
        };
    }, []);

    // Observe the fixed element to place it after footer once user has scroll to bottom
    useEffect(() => {
        const footer = document.getElementsByTagName('footer')[0];
        const fixedElement = document.getElementById(FIXED_ELEMENT_ID);

        if (fixedElement) {
            const observer = new IntersectionObserver(([entry]) => {
                const fixedElementContainer = document.createElement('div');

                fixedElementContainer.id = CONTAINER_FIXED_ELEMENT_ID;

                if (entry.isIntersecting) {
                    fixedElementContainer.style.height = `${fixedElement.getBoundingClientRect().height}px`;
                    footer.append(fixedElementContainer);
                } else {
                    const fixedElementContainerId = document.getElementById('fixed-container');
                    if (fixedElementContainerId) {
                        fixedElementContainerId.remove();
                    }
                }
            });

            observer.observe(footer);

            return () => {
                observer.disconnect();
            };
        }
    }, [isVisible]);

    // Move the UIBackToTop above the fixed bar when it is visible
    useEffect(() => {
        const backToTopElement = document.getElementById(BACK_TO_TOP_ID);

        if (backToTopElement) {
            const fixedElement = document.getElementById(FIXED_ELEMENT_ID);
            if (!isVisible && fixedElement) {
                backToTopElement.style.bottom = `${fixedElement.getBoundingClientRect().height + BACK_TO_TOP_OFFSET}px`;
            } else {
                backToTopElement.style.removeProperty('bottom');
            }
        }
    }, [isVisible]);

    const addToBasketCta = useMemo(() => {
        return (
            <SelectorAddToBasketButton
                sku={sku}
                productDeliveryInfoData={productDeliveryInfoData}
                isNotifyMeEnabledData={isNotifyMeEnabledData}
                hasShortLabel={!isMinTablet && !isVisible}
                vipBrands={vipBrands}
                locale={locale}
                fontFamily={fontFamily}
                isVisible={isVisible}
            />
        );
    }, [isMinTablet, isNotifyMeEnabledData, isVisible, locale, productDeliveryInfoData, sku, vipBrands, fontFamily]);

    return (
        <>
            <div id={'selector-zone'} className={'flex flex-col gap-1'}>
                <VariantSelectorZone
                    type={type}
                    sku={sku}
                    variants={variants}
                    formats={formats}
                    currency={currency}
                    enableDiscountInfo={priceProps.enableDiscountInfo}
                    priceAlwaysBlack={priceProps.priceAlwaysBlack}
                    squaredShade={squaredShade}
                    locale={locale}
                    fontFamily={fontFamily}
                />
                <div id={STATIC_CTA_ID} className={'flex flex-row gap-0.5 w-full'}>
                    {addToBasketCta}
                    <SelectorWishlistButton productId={id} sku={sku} productName={productName} locale={locale} />
                </div>
                {isRedDot && redDotText && <UIRedDotInformation redDotText={redDotText} />}
            </div>
            {!isVisible && (
                <section
                    id={FIXED_ELEMENT_ID}
                    data-testid={DataTestConfig.testIds.addToBasketFixed}
                    className={clsx(
                        'fixed bottom-0 left-0 w-full z-addToBasketSticky',
                        'flex justify-center',
                        'bg-background-l0',
                        'border-solid border-t-medium border-border-primary shadow-stickyBottom',
                    )}
                >
                    <div
                        className={clsx(
                            'max-w-full desktop:max-w-largestDesktop',
                            'px-1 py-0.75 desktopS:p-1 desktop:p-1.5',
                            'flex-1 flex flex-col desktopS:flex-row',
                            'justify-center gap-0.5 desktopS:gap-1.5',
                        )}
                    >
                        <div
                            className={
                                'desktopS:flex-1 desktopS:w-fixedAddToCartDesktopS desktop:w-fixedAddToCartDesktop tablet:py-0 flex justify-between gap-1 items-center'
                            }
                        >
                            <div className={'flex justify-start gap-1 items-center min-w-[0px]'}>
                                <GlowSkeleton
                                    width={IMAGE_WIDTH}
                                    height={IMAGE_HEIGHT}
                                    containerClassName={'hidden desktopS:flex'}
                                />
                                <div className={'whitespace-nowrap overflow-hidden'}>
                                    <GlowTextBodySmall
                                        text={brandName}
                                        fontFamily={fontFamily}
                                        className={'h-fixedAddToCartBrandName leading-6 overflow-hidden text-ellipsis'}
                                    />
                                    <GlowTextBodySmall
                                        text={productName}
                                        fontFamily={fontFamily}
                                        fontWeight={'bold'}
                                        className={'overflow-hidden text-ellipsis'}
                                    />
                                </div>
                            </div>
                            <GlowPrice fontFamily={fontFamily} {...priceProps} className={'items-end'} />
                        </div>
                        <div className={'desktopS:flex-1 flex gap-0.5 h-fixedAddToCart'}>
                            <VariantSelector
                                type={type}
                                sku={sku}
                                variants={variants}
                                formats={formats}
                                currency={currency}
                                enableDiscountInfo={priceProps.enableDiscountInfo}
                                priceAlwaysBlack={priceProps.priceAlwaysBlack}
                                squaredShade={squaredShade}
                                locale={locale}
                                fontFamily={fontFamily}
                            />
                            {addToBasketCta}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

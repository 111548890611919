import { useTranslations } from 'next-intl';
import { StoreInfo } from '@headless-workspace/domain/common/server';
import { Component } from '@headless-workspace/typings';
import { NoStoreFound } from './NoStoreFound';
import { DisplayOption } from './StoresDeliveryContainer';
import { StoreSelectionMenuMap } from './map';
import { StoresList } from './storeDeliveryContainer/StoresList';

type StoreSliderDisplayProps = {
    stores: StoreInfo[];
    fetched: boolean;
    userPosition: GeolocationPosition | null;
    displayOption: DisplayOption;
    highlightedStoreId?: string;
    setHighlightedStore: (store: StoreInfo) => void;
    favoriteStoreId: string;
    currentTime: string;
};

export const StoreSliderDisplay: Component<StoreSliderDisplayProps> = ({
    stores,
    fetched,
    userPosition,
    displayOption,
    highlightedStoreId,
    setHighlightedStore,
    favoriteStoreId,
    currentTime,
}) => {
    const t = useTranslations('Discover.Pdp.deliveryZone.stores');

    if (!fetched) {
        return null;
    }

    if (stores.length === 0) {
        return <NoStoreFound />;
    }

    if (displayOption === DisplayOption.Map) {
        return (
            <StoreSelectionMenuMap
                highlightedStoreId={highlightedStoreId}
                setHighlightedStore={setHighlightedStore}
                stores={stores}
                currentUserPosition={userPosition}
                favoriteStoreId={favoriteStoreId}
            />
        );
    }
    return (
        <StoresList
            favoriteStoreId={favoriteStoreId}
            stores={stores}
            listCaption={t('listCaption', {
                currentTime,
            })}
            setHighlightedStore={setHighlightedStore}
        />
    );
};

import { Component } from '@headless-workspace/typings';

const quickAccessMockValue = 'Quick access';

export const QuickAccessSection: Component = () => {
    return (
        <section id={'product-list-quick-access-section'} className={'px-1 py-0.5'}>
            <div id={'product-list-quick-access'} className={'bg-background-skeleton'}>
                {quickAccessMockValue}
            </div>
        </section>
    );
};

import { Component } from '@headless-workspace/typings';
import { ProductListContentSection } from './ProductListContentSection';
import { BannerSection } from './banner';
import { BreadcrumbSection } from './breadcrumb';
import { QuickAccessSection } from './quickAccess';
import { StickyFilterSortingSection } from './stickyFilterSorting';

export const ProductListContainerSection: Component = () => {
    return (
        <section id={'product-list-container'}>
            <BreadcrumbSection />
            <BannerSection />
            <QuickAccessSection />
            <StickyFilterSortingSection />
            <ProductListContentSection />
        </section>
    );
};

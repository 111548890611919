'use client';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import {
    DEFAULT_HIGH_ZOOM_LVL,
    DEFAULT_ZOOM_LVL,
    PRODUCT_DETAIL_VISUAL_MODAL_ID,
    ZOOM_DELTA_LVL,
} from '@headless-workspace/config';
import { UIContext, UIFullScreenMobileCarousel, useCarouselLogic } from '@headless-workspace/core-ui';
import { isImage, isVideo, MediaValue } from '@headless-workspace/domain/common/client';
import { GlowButton, GlowClickable, GlowIcon, GlowImage, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { EmblaOptionsType } from 'embla-carousel';
import Fade from 'embla-carousel-fade';
import { YoutubeScriptProvider } from '../../providers';
import { ProductDetailVisualVideo, YT_PLAYER_STATES } from './ProductDetailVisualVideo';
import { ProductDetailZoomableImage } from './ProductDetailZoomableImage';

export type ProductDetailVisualModalProps = {
    medias: MediaValue[];
    activeIndex: number;
};

const TARGET_ORIGIN = 'https://www.youtube.com';
export const CLOSE_BUTTON_TEST_ID = 'productDetailVisualModalCloseButton';

export const ProductDetailVisualModal: Component<ProductDetailVisualModalProps> = ({ medias, activeIndex }) => {
    const t = useTranslations('Discover.Pdp.carousel');
    const [videoStates, setVideoStates] = useState<Record<string, YT.PlayerState>>({});

    const {
        menuModal: { close: closeMenu, sideMenuModalList: modalList },
    } = useContext(UIContext);

    const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LVL);
    const [isVideoPlaying, setIsVideoPlaying] = useState(true);

    const isZoomOutDisabled = zoomLevel <= DEFAULT_ZOOM_LVL;
    const isZoomInDisabled = zoomLevel >= DEFAULT_HIGH_ZOOM_LVL;
    const isMenuModalOpen = modalList[PRODUCT_DETAIL_VISUAL_MODAL_ID]?.isOpen ?? false;

    const options: EmblaOptionsType = {
        loop: true,
        startIndex: activeIndex,
    };

    useEffect(() => {
        if (isMenuModalOpen) {
            emblaApiCarousel?.scrollTo(activeIndex, true);
        }
    }, [isMenuModalOpen, activeIndex]);

    // carousel master
    const {
        emblaRef: emblaRefCarousel,
        emblaApi: emblaApiCarousel,
        prevBtnDisabled: prevBtnDisabledCarousel,
        nextBtnDisabled: nextBtnDisabledCarousel,
        onPrevButtonClick: onPrevButtonClickCarousel,
        onNextButtonClick: onNextButtonClickCarousel,
        selectedIndex: selectedIndexCarousel,
    } = useCarouselLogic(options, [Fade()]);

    // carousel thumbnail ( follow carousel master index )
    const {
        emblaRef: emblaRefThumbnail,
        prevBtnDisabled: prevBtnDisabledThumbnail,
        nextBtnDisabled: nextBtnDisabledThumbnail,
        onPrevButtonClick: onPrevButtonClickThumbnail,
        onNextButtonClick: onNextButtonClickThumbnail,
        selectedIndex: selectedIndexThumbnail,
    } = useCarouselLogic(options);

    const controlVideo = (media?: MediaValue) => {
        if (!media) {
            return;
        }

        const iframe = document.getElementById(`${media.id}`) as HTMLIFrameElement;

        setIsVideoPlaying((prevIsVideoPlaying) => {
            const contentWindow = iframe?.contentWindow;
            if (contentWindow) {
                contentWindow.postMessage(
                    JSON.stringify({
                        event: 'command',
                        func: isVideoPlaying ? 'pauseVideo' : 'playVideo',
                        args: [],
                    }),
                    TARGET_ORIGIN,
                );
                return !prevIsVideoPlaying;
            } else {
                return prevIsVideoPlaying;
            }
        });
    };

    useEffect(() => {
        if (isMenuModalOpen && selectedIndexCarousel !== undefined) {
            // Find and focus the current thumbnail
            const currentThumb = document.querySelector(`#product-detail-visual-thumb-${selectedIndexCarousel}`);
            if (currentThumb instanceof HTMLElement) {
                currentThumb.focus();
            }
        }
    }, [isMenuModalOpen, selectedIndexCarousel]);

    const onProductVisualThumbClick =
        (index: number, media?: MediaValue, toggle = false) =>
        () => {
            if (toggle) {
                controlVideo(media);
            } else {
                emblaApiCarousel?.scrollTo(index);
                setZoomLevel(DEFAULT_ZOOM_LVL);
                setIsVideoPlaying(true);
            }
        };

    useEffect(() => {
        if (selectedIndexThumbnail !== selectedIndexCarousel) {
            onProductVisualThumbClick(selectedIndexThumbnail)();
        }
    }, [selectedIndexThumbnail]);

    const handleZoom = (delta: number) => () => {
        setZoomLevel((prevZoom) => Math.min(DEFAULT_HIGH_ZOOM_LVL, Math.max(DEFAULT_ZOOM_LVL, prevZoom + delta)));
    };

    const onProductVisualClickCloseModal = useCallback(() => {
        closeMenu(PRODUCT_DETAIL_VISUAL_MODAL_ID);
    }, [closeMenu]);

    const sharedCarouselProps = useMemo(
        () => ({
            items: medias,
            prevBtnAriaLabel: t('ariaLabel.prevBtn'),
            nextBtnAriaLabel: t('ariaLabel.nextBtn'),
            thumbArialLabel: t('ariaLabel.thumb'),
            showDot: false,
        }),
        [medias, t],
    );
    const onVideoStateChange = (index: number) => (state: YT.PlayerState) => {
        setVideoStates((prevStates) => ({
            ...prevStates,
            [index]: state,
        }));
    };

    return (
        <div
            className={
                'tablet:w-fullViewport desktopS:w-full bg-background-l0 relative p-1 flex flex-col h-fullViewport w-auto'
            }
        >
            <YoutubeScriptProvider>
                <div className={'flex justify-end'}>
                    <GlowClickable
                        dataTestId={CLOSE_BUTTON_TEST_ID}
                        onClick={onProductVisualClickCloseModal}
                        ariaLabel={t('modal.closeBtn')}
                        content={{
                            icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                        }}
                    />
                </div>
                <div className={'flex flex-col flex-1 items-center justify-center gap-0.5'}>
                    <UIFullScreenMobileCarousel
                        {...sharedCarouselProps}
                        className={
                            'w-productModal tablet:w-productModalTablet desktopS:w-productModalDesktopS desktop:w-productModalDesktop gap-0.5 relative'
                        }
                        renderItem={(media, index) =>
                            isImage(media) ? (
                                <ProductDetailZoomableImage
                                    key={media.url}
                                    image={media}
                                    emblaApi={emblaApiCarousel}
                                    zoomLevel={isVideo(media) ? 1 : zoomLevel}
                                />
                            ) : (
                                <ProductDetailVisualVideo
                                    key={media.url}
                                    onStateChange={onVideoStateChange(index)}
                                    media={media}
                                    isDisplayed={isMenuModalOpen && selectedIndexCarousel === index}
                                />
                            )
                        }
                        emblaRef={emblaRefCarousel}
                        prevBtnDisabled={prevBtnDisabledCarousel}
                        nextBtnDisabled={nextBtnDisabledCarousel}
                        onPrevButtonClick={onPrevButtonClickCarousel}
                        onNextButtonClick={onNextButtonClickCarousel}
                        selectedIndex={selectedIndexCarousel}
                        navButtonVariant={'tertiary'}
                        navigationButtonClassName={'hidden desktopS:flex'}
                    />

                    <div
                        className={clsx('flex gap-x-0.5 justify-center', {
                            invisible: isVideo(medias[selectedIndexCarousel]),
                        })}
                    >
                        <GlowButton
                            variant={isZoomOutDisabled ? 'disabled' : 'tertiary'}
                            onClick={handleZoom(-ZOOM_DELTA_LVL)}
                            disabled={isZoomOutDisabled}
                            Icon={Icons.ZoomOut}
                            ariaLabel={t('ariaLabel.zoomOut')}
                        />
                        <GlowButton
                            onClick={handleZoom(ZOOM_DELTA_LVL)}
                            disabled={isZoomInDisabled}
                            Icon={Icons.ZoomIn}
                            ariaLabel={t('ariaLabel.zoomIn')}
                            variant={isZoomInDisabled ? 'disabled' : 'tertiary'}
                        />
                    </div>
                </div>

                <UIFullScreenMobileCarousel
                    {...sharedCarouselProps}
                    className={'w-productThumb tablet:w-auto justify-center gap-0.25'}
                    navButtonIconType={'large'}
                    renderItem={(media, index) => {
                        const isCurrentVisual = selectedIndexCarousel === index;
                        return (
                            <GlowClickable
                                id={`product-detail-visual-thumb-${index}`}
                                key={media.url}
                                onClick={onProductVisualThumbClick(index, media, isCurrentVisual)}
                                className={clsx(
                                    'relative w-productModalThumbImg aspect-1/1 group border-solid hover:border-border-selected hover:border-bold cursor-pointer rounded-0.25 overflow-hidden',
                                    isCurrentVisual
                                        ? 'opacity-100 border-border-selected border-bold'
                                        : 'opacity-65 hover:opacity-100 border-border-primary border-medium',
                                )}
                                content={{
                                    icon: (
                                        <>
                                            <GlowImage
                                                image={media}
                                                className={
                                                    'flex items-center justify-center w-productModalThumbImg aspect-1/1 cursor-pointer'
                                                }
                                                imgClassName={clsx(
                                                    'object-cover aspect-1/1 ease-in duration-moderate3 group-hover:w-productModalAnimatedThumbImg group-hover:rounded-0.125',
                                                    isCurrentVisual
                                                        ? 'w-productModalAnimatedThumbImg rounded-0.125'
                                                        : 'w-productModalThumbImg',
                                                )}
                                            />
                                            {isVideo(media) && (
                                                <div
                                                    className={clsx(
                                                        'absolute top-0 left-0 w-full aspect-1/1 flex items-center justify-center ease-in duration-moderate3',
                                                        !isCurrentVisual &&
                                                            // FIXME : The issue with Tailwind will be resolved in the next version
                                                            'bg-background-overlay group-hover:bg-[transparent] group-hover:bg-opacity-0',
                                                    )}
                                                >
                                                    <GlowIcon
                                                        Icon={
                                                            [
                                                                YT_PLAYER_STATES.PLAYING,
                                                                YT_PLAYER_STATES.BUFFERING,
                                                            ].includes(videoStates[index])
                                                                ? Icons.Pause
                                                                : Icons.Play
                                                        }
                                                        type={'small'}
                                                        className={'text-text-primary-inverse'}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        );
                    }}
                    prevBtnDisabled={prevBtnDisabledThumbnail}
                    nextBtnDisabled={nextBtnDisabledThumbnail}
                    onPrevButtonClick={onPrevButtonClickThumbnail}
                    onNextButtonClick={onNextButtonClickThumbnail}
                    selectedIndex={selectedIndexThumbnail}
                    emblaRef={emblaRefThumbnail}
                    navButtonVariant={'tertiary'}
                />
            </YoutubeScriptProvider>
        </div>
    );
};

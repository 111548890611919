import clsx from 'clsx';
import { UIFullScreenMobileCarousel, useCarouselLogic } from '@headless-workspace/core-ui';
import { FlagValue, isVideo, MediaValue } from '@headless-workspace/domain/common/client';
import { GlowClickable, GlowIcon, GlowImage, Icons, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ProductVisualFlags } from './ProductVisualFlags';
import { SocialProof } from './SocialProof';

export type ProductVisualMobileProps = PropsWithClassName & {
    medias: MediaValue[];
    flags?: FlagValue[];
    onProductVisualClick: (index: number) => void;
    prevBtnAriaLabel: string;
    nextBtnAriaLabel: string;
    thumbArialLabel: string;
    socialProofLabel?: string;
    visualClassName: string;
};

export const ProductVisualMobile: Component<ProductVisualMobileProps> = ({
    medias,
    flags,
    onProductVisualClick,
    prevBtnAriaLabel,
    nextBtnAriaLabel,
    thumbArialLabel,
    socialProofLabel,
    className,
    visualClassName,
}) => {
    const { emblaRef, prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick, selectedIndex } =
        useCarouselLogic({ loop: true });

    const onCarouselProductVisualClick = (index: number) => () => {
        onProductVisualClick(index);
    };

    return (
        <div className={'flex flex-col gap-1 desktopS:hidden'}>
            <ProductVisualFlags flags={flags} className={'px-1'} />
            <UIFullScreenMobileCarousel
                className={clsx('relative', className)}
                items={medias}
                renderItem={(media, index) => (
                    <GlowClickable
                        key={media.url}
                        onClick={onCarouselProductVisualClick(index)}
                        className={'relative'}
                        content={{
                            icon: (
                                <>
                                    <GlowImage
                                        image={media}
                                        className={clsx('aspect-1/1 relative', visualClassName)}
                                        imgClassName={'object-cover aspect-1/1 w-full'}
                                    />
                                    {isVideo(media) && (
                                        <span
                                            className={
                                                'absolute top-0 left-0 w-full aspect-1/1 flex items-center justify-center'
                                            }
                                        >
                                            <GlowIcon
                                                Icon={Icons.Play}
                                                type={'small'}
                                                className={
                                                    'text-text-primary-inverse w-ProductVideoPlayPauseIcon h-ProductVideoPlayPauseIcon tablet:w-ProductVideoPlayPauseIconTablet tablet:h-ProductVideoPlayPauseIconTablet'
                                                }
                                            />
                                        </span>
                                    )}
                                    {index === 0 && <SocialProof socialProofLabel={socialProofLabel} />}
                                </>
                            ),
                        }}
                    />
                )}
                prevBtnAriaLabel={prevBtnAriaLabel}
                nextBtnAriaLabel={nextBtnAriaLabel}
                thumbArialLabel={thumbArialLabel}
                emblaRef={emblaRef}
                prevBtnDisabled={prevBtnDisabled}
                nextBtnDisabled={nextBtnDisabled}
                onPrevButtonClick={onPrevButtonClick}
                onNextButtonClick={onNextButtonClick}
                selectedIndex={selectedIndex}
                navButtonIconType={'medium'}
                navButtonVariant={'transparent'}
            />
        </div>
    );
};

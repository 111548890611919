import { clsx } from 'clsx';
import { GlowTag, PropsWithClassName } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FlagValue } from 'domains/common/src';

type ProductVisualFlagsProps = PropsWithClassName & {
    flags?: FlagValue[];
};

export const ProductVisualFlags: Component<ProductVisualFlagsProps> = ({ flags, className }) => {
    if (!flags?.length) {
        return null;
    }

    return (
        <ul className={clsx(className, 'flex gap-0.5')}>
            {flags.map((flag) => (
                <li key={flag.id}>
                    <GlowTag label={flag.label} size={'large'} type={'marketing'} />
                </li>
            ))}
        </ul>
    );
};

import { GlowDivider, GlowSkeleton } from '@headless-workspace/glow-ds';

export const QuickViewProductMenuSkeleton = () => {
    return (
        <div className={'h-fullViewport w-fullViewport tablet:w-sideMenuTablet flex flex-col'}>
            <div
                className={
                    'flex flex-row justify-between items-center p-1 gap-1 border-b-medium border-b-border-subtle border-solid h-headerFixedNavDesktop'
                }
            >
                <GlowSkeleton width={'25.5rem'} height={'1.875rem'} />
            </div>
            <div className={'flex flex-col p-1 gap-1.5 h-quickViewProductMenuContainer overflow-x-hidden'}>
                <GlowSkeleton width={'21.438rem'} height={'21.438rem'} itemClassName={'m-auto'} />
                <div className={'flex flex-col gap-0.5'}>
                    <GlowSkeleton width={'27.5rem'} height={'1.25rem'} />
                    <GlowSkeleton width={'6.938rem'} height={'1.25rem'} />
                    <GlowSkeleton width={'6.938rem'} height={'1.25rem'} />
                </div>
                <GlowSkeleton width={'27.5rem'} height={'1.25rem'} />
                <div className={'flex flex-col gap-0.5'}>
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3.75rem'} />
                </div>
                <GlowSkeleton width={'27.5rem'} height={'7.15rem'} />
            </div>

            <div className={'mt-auto'}>
                <GlowDivider />
                <div className={'p-1 flex flex-col gap-0.5 items-center justify-center'}>
                    <GlowSkeleton width={'27.5rem'} height={'3rem'} />
                    <GlowSkeleton width={'27.5rem'} height={'3rem'} />
                </div>
            </div>
        </div>
    );
};

import { GlowTextBody, GlowTextBodySmall } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

const bannerProductListMockValue = {
    title: 'Maquillage',
    text:
        'Essentiel beauté incontournable, le parfum est notre meilleur atout ! Teint frais, lèvres gourmandes et\n' +
        'smoky eyes, le makeup parfait est à portée de main. On laisse libre cours à toutes nos envies maquillage\n' +
        "en créant des looks à l'infini ! Best-sellers ou nouveautés beauté, le maquillage n'a jamais été aussi\n" +
        "fun qu'avec Sephora.",
};

export const BannerSection: Component = () => {
    return (
        <section id={'product-list-banner'} className={'flex flex-col px-1'}>
            <div className={'p-1 rounded-0.5 bg-gradient-makeup'}>
                <GlowTextBody text={bannerProductListMockValue.title} fontSize={'title2'} />
                <GlowTextBodySmall text={bannerProductListMockValue.text} className={'pt-1.5'} />
            </div>
        </section>
    );
};

'use client';

import { useTranslations } from 'next-intl';
import { UIStoreInfoCardProps } from '@headless-workspace/core-ui';
import { StoreInfo } from '@headless-workspace/domain/common/server';
import { FormatHelpers, StoreHelpers } from '@headless-workspace/utils';

type UseStoreInfoCardUtilsReturnType = {
    mapStoreInfoToUIStoreInfoCardStore: (storeInfo: StoreInfo) => UIStoreInfoCardProps['store'];
};

export const useStoreInfoCardUtils = (): UseStoreInfoCardUtilsReturnType => {
    const t = useTranslations('Header.storesAndServices.banner.label');
    const translatedDays = [t('days.1'), t('days.2'), t('days.3'), t('days.4'), t('days.5'), t('days.6'), t('days.7')];

    const mapStoreInfoToUIStoreInfoCardStore = (storeInfo: StoreInfo): UIStoreInfoCardProps['store'] => {
        const storeOpeningInfo = StoreHelpers.getStoreOpeningInfo(storeInfo.schedule, {
            days: translatedDays,
            today: t('today'),
            openUntil: t('openUntil'),
            opens: t('opens'),
            opensAt: t('opensAt'),
            tomorrow: t('tomorrow'),
        });
        return {
            id: storeInfo.id,
            name: storeInfo.name,
            isSelected: false,
            addressText: FormatHelpers.formatAddress(storeInfo.address, storeInfo.city, storeInfo.postalCode),
            distanceText: storeInfo.distanceText ?? '',
            productAvailability: {
                isAvailable: true,
                text: t('productAvailability'),
            },
            shippingMethodInfo: {
                groupId: storeInfo.shippingMethods.groupId,
                freeLabel: t('shippingCost', {
                    checkIsFreeShipping: storeInfo.shippingMethods.isFree,
                    shippingOptions: storeInfo.shippingMethods.groupId,
                }),
                deliveryMessage: storeInfo.shippingMethods.deliveryMessage,
            },
            scheduleInfo: {
                openStatusTitle: storeOpeningInfo.isOpenNow ? t('open') : t('closed'),
                openStatusText: storeOpeningInfo.statusPhrase,
                openHours: FormatHelpers.formatOpenHours(
                    storeInfo.schedule.map(({ day, workingHours }) => ({ day, hours: workingHours ?? [] })),
                    translatedDays,
                    t('closed'),
                ),
            },
        };
    };

    return {
        mapStoreInfoToUIStoreInfoCardStore,
    };
};

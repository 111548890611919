import { GlowTextBodySmall } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

const seoProductListMockValue = {
    text:
        'A la constante recherche du parfum parfait, trouver l’essence qui nous ressemble peut devenir une vraie mission. ' +
        'Sephora l’accepte en vous dévoilant le meilleur de la parfumerie. De ses fragrances uniques à l’image d’un parfum ' +
        'Kenzo à une essence subtile comme un parfum Armani, il devient le reflet de notre humeur, de notre personnalité. ' +
        'Incontournable, on préfère l’eau de parfum pour ses notes enivrantes, mystérieuses. Puissante, elle dévoile ' +
        'chacune de ses notes avec audace et détermination. Floral, boisé ou ambré, le parfum est emblématique : habillant ' +
        'les hommes, sublimant les femmes. Fraîches et naturelles, les notes subtiles d’une eau de toilette seront parfaites. ' +
        'Elle suit notre humeur révélant peu à peu la tendresse de sa note de cœur. Discret ou puissant, Sephora fait du ' +
        'parfum l’arme de séduction ultime ! On laisse parler notre cœur et notre personnalité en choisissant le parfum ' +
        'femme que l’on trouve si sensuel ou ce parfum homme qu’on aime tant. Parfum addict ou en panne d’idées cadeaux ? ' +
        'On se laisse tenter par un soin corps parfumé pour des notes de parfums longue tenue ou on opte pour un coffret ' +
        'parfum irrésistible qui fera toujours plaisir.',
};

export const SeoBottomSection: Component = () => {
    return (
        <section id={'product-list-seo-bottom-section'}>
            <GlowTextBodySmall text={seoProductListMockValue.text} className={'px-1.5 py-2 justify-center'} />
        </section>
    );
};

'use client';

import { useEffect } from 'react';
import { Locale } from '@headless-workspace/config';
import { DataLayerKey, HeaderDidMount, useDataLayer } from '@headless-workspace/domain/common/client';
import { Component } from '@headless-workspace/typings';

type ProductListDidMountProps = {
    locale: Locale;
};

const DATA_LAYER_CONNECTION_ACTION = '';

export const ProductListDidMount: Component<ProductListDidMountProps> = ({ locale }) => {
    const { trackEvent, addVariable, addVariables } = useDataLayer({
        [DataLayerKey.EnvTemplate]: 'product list',
        [DataLayerKey.ProductDisplayType]: 'product list',
    });

    useEffect(() => {
        if (typeof document !== 'undefined') {
            const productCategoryElement = document.querySelector('#plp-container');
            const productCategoryId = productCategoryElement?.getAttribute('data-category-id') ?? '';
            addVariable(DataLayerKey.ProductCategoryId, productCategoryId);
        }
    }, [addVariable]);

    return (
        <HeaderDidMount
            dataLayerConnectionAction={DATA_LAYER_CONNECTION_ACTION}
            trackEvent={trackEvent}
            locale={locale}
            addVariables={addVariables}
        />
    );
};

'use client';

import { ReactNode, useCallback, useContext } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { PRODUCT_INFORMATION_MENU_ID } from '@headless-workspace/config';
import { UIContext, useToasts } from '@headless-workspace/core-ui';
import {
    GlowButton,
    GlowClickable,
    GlowDivider,
    GlowIcon,
    GlowTitle,
    Icons,
    PropsWithFontFamily,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export enum ProductDetailInfoModalContext {
    storeSelection = 'store-selection',
}

type ProductDetailInfoModalProps = Partial<PropsWithFontFamily> & {
    renderInformation: ReactNode;
    title: string;
    closeButtonLabel?: string;
    contentContainerClassName?: string;
    actionButtonLabel?: string;
    actionButtonHref?: string;
    productModalId?: string;
    isChevronRight?: boolean;
    context?: ProductDetailInfoModalContext;
};

export const ProductDetailInfoModal: Component<ProductDetailInfoModalProps> = ({
    renderInformation,
    title,
    closeButtonLabel,
    actionButtonLabel,
    actionButtonHref,
    contentContainerClassName,
    productModalId,
    fontFamily = 'brand',
    isChevronRight = false,
    context,
}) => {
    const {
        menuModal: { close: closeMenu },
    } = useContext(UIContext);
    const { notifyError } = useToasts();
    const t = useTranslations('Discover.Pdp.deliveryZone.stores');

    const hasTwoButtons = actionButtonLabel && actionButtonHref;

    const closeProductInformationModal = useCallback(() => {
        if (context === ProductDetailInfoModalContext.storeSelection) {
            notifyError(t('error.noStoreSelected'));
        } else {
            closeMenu(productModalId ?? PRODUCT_INFORMATION_MENU_ID);
        }
    }, [closeMenu, context, notifyError, productModalId, t]);

    return (
        <div
            id={'product-detail-info-modal'}
            className={'h-fullViewport w-fullViewport tablet:w-sideMenuTablet flex flex-col bg-background-l3'}
        >
            <div
                className={
                    'flex flex-row justify-between items-center p-1 gap-1 border-b-medium border-b-border-subtle border-solid h-headerFixedNavDesktop'
                }
            >
                {isChevronRight && (
                    <GlowClickable
                        onClick={closeProductInformationModal}
                        content={{
                            icon: (
                                <GlowIcon
                                    Icon={Icons.ChevronRight}
                                    type={'large'}
                                    className={'text-text-primary rotate-180'}
                                />
                            ),
                        }}
                    />
                )}
                <GlowTitle text={title} fontSize={'title4'} fontFamily={fontFamily} className={'flex-1 text-left'} />
                <GlowClickable
                    onClick={closeProductInformationModal}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} className={'text-text-primary'} />,
                    }}
                />
            </div>
            <div className={clsx('flex flex-1 flex-col', contentContainerClassName)}>{renderInformation}</div>
            {closeButtonLabel && (
                <>
                    <GlowDivider />
                    <div className={clsx('flex items-center justify-center p-1', hasTwoButtons && 'flex-col gap-0.5')}>
                        {hasTwoButtons ? (
                            <>
                                <GlowButton
                                    className={'flex-1 w-full'}
                                    href={actionButtonHref}
                                    label={actionButtonLabel}
                                    fontFamily={fontFamily}
                                />
                                <GlowButton
                                    variant={'secondary'}
                                    onClick={closeProductInformationModal}
                                    className={'w-full'}
                                    label={closeButtonLabel}
                                    fontFamily={fontFamily}
                                />
                            </>
                        ) : (
                            <GlowButton
                                className={'flex-1 w-full'}
                                onClick={closeProductInformationModal}
                                label={closeButtonLabel}
                                fontFamily={fontFamily}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

'use client';

import { useTranslations } from 'next-intl';
import { AsyncDataWithoutResult, unwrapResultSuccess } from '@headless-workspace/core-domain';
import { StockStatus, useBasketMutations } from '@headless-workspace/domain/common/client';
import { BasketProductValue } from '@headless-workspace/domain/common/server';
import { IconComponentType, Icons } from '@headless-workspace/glow-ds';
import { FunctionHelpers } from '@headless-workspace/utils';
import { ProductDetailDeliveryInfoValue } from '../delivery';

type UseProductBasketCTAsParams = {
    sku: string;
    productDeliveryInfoData: AsyncDataWithoutResult<ProductDetailDeliveryInfoValue>;
    isNotifyMeEnabledData: AsyncDataWithoutResult<boolean>;
    hasShortLabel: boolean;
};

export type UseProductBasketCTAsReturnType = {
    isLoading: boolean;
    cta: CTAType;
    isAddingToBasket: boolean;
};

type CTAType = {
    Icon?: IconComponentType;
    label: string;
    onClick: () => void | Promise<BasketProductValue | undefined>;
    variant: 'primary' | 'secondary' | null;
};

export const useProductBasketCTA = ({
    sku,
    productDeliveryInfoData,
    isNotifyMeEnabledData,
    hasShortLabel,
}: UseProductBasketCTAsParams): UseProductBasketCTAsReturnType => {
    const t = useTranslations('Discover.Pdp.information.basket');
    const { isMutating: isAddingToBasket, add: addToBasket } = useBasketMutations();

    const hasSelectedStore = !!productDeliveryInfoData.data?.currentStore;

    const addToBasketCondition =
        productDeliveryInfoData.data?.allStores.stockStatus === StockStatus.Available ||
        productDeliveryInfoData.data?.shipping.stockStatus === StockStatus.Available;

    const chooseStoreCondition =
        !hasSelectedStore &&
        productDeliveryInfoData.data?.allStores.stockStatus === StockStatus.Available &&
        productDeliveryInfoData.data?.shipping.stockStatus !== StockStatus.Available;

    const updateStoreCondition =
        hasSelectedStore &&
        productDeliveryInfoData.data?.currentStore?.stockStatus !== StockStatus.Available &&
        productDeliveryInfoData.data?.allStores.stockStatus === StockStatus.Available &&
        productDeliveryInfoData.data?.shipping.stockStatus !== StockStatus.Available;

    const notifyMeCondition =
        isNotifyMeEnabledData.data &&
        productDeliveryInfoData.data?.allStores.stockStatus !== StockStatus.Available &&
        productDeliveryInfoData.data?.shipping.stockStatus !== StockStatus.Available;

    const handleAddToBasket = async (): Promise<BasketProductValue | undefined> => {
        const addToBasketResult = await addToBasket({ id: sku });

        if (addToBasketResult.type === 'success') {
            const customerBasket = unwrapResultSuccess(addToBasketResult);
            return customerBasket?.products.find((p) => p.productId === sku);
        }

        return undefined;
    };

    const getCTA = (): CTAType => {
        if (addToBasketCondition) {
            return {
                label: hasShortLabel ? t('action.shortLabel.add') : t('action.add'),
                onClick: handleAddToBasket,
                variant: 'primary',
            };
        }
        if (chooseStoreCondition) {
            return { label: t('action.chooseStore'), onClick: FunctionHelpers.emptyFn, variant: 'primary' };
        }
        if (updateStoreCondition) {
            return { label: t('action.changeStore'), onClick: FunctionHelpers.emptyFn, variant: 'primary' };
        }
        if (notifyMeCondition) {
            return {
                Icon: Icons.Notification,
                label: t('action.outOfStockAndNotifyMe'),
                onClick: FunctionHelpers.emptyFn,
                variant: 'secondary',
            };
        }
        return { label: t('action.outOfStock'), onClick: FunctionHelpers.emptyFn, variant: null };
    };

    return {
        isLoading: productDeliveryInfoData.isLoading || isNotifyMeEnabledData.isLoading,
        cta: getCTA(),
        isAddingToBasket,
    };
};

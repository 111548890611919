'use client';

import { useMemo } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { FidIcon, useUserInfo } from '@headless-workspace/domain/common/client';
import {
    getConnectedUserInfo,
    UserSephoraCard,
    UserSephoraCardUnlimited,
} from '@headless-workspace/domain/common/server';
import { GlowTextBody, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LoyaltyHelpers } from '@headless-workspace/utils';
import { ProductDetailInfoCTA } from '../productDetailModal/ProductDetailInfoCTA';
import { ProductDetailInfoModal } from '../productDetailModal/ProductDetailInfoModal';
import { PushLoyaltyModal } from './PushLoyaltyModal';
import styles from './loyalty.module.css';

type PushLoyaltyContentProps = {
    userCard: UserSephoraCard | UserSephoraCardUnlimited;
    customerFirstName: string;
    loyaltyPoints: string;
};

const PRODUCT_PUSH_LOYALTY_ID = 'product-push-loyalty';

export const VIP_BORDER_CLASSES = 'border-medium border-solid border-border-primary';

const PushLoyaltyContent: Component<PushLoyaltyContentProps> = ({ userCard, customerFirstName, loyaltyPoints }) => {
    const t = useTranslations('Discover.Pdp.loyalty');

    return (
        <span className={'flex flex-row gap-0.5 w-full text-text-primary'}>
            <span className={'flex justify-center items-center'}>
                <FidIcon userCard={userCard} displayPreBuyIcon={true} />
            </span>

            <span className={'flex flex-col gap-0.5 text-left'}>
                <GlowTextBody text={t('label.fidAdvantage')} fontWeight={'bold'} TagName={'span'} />
                <span className={'flex flex-row gap-0.5'}>
                    <GlowTextBody text={customerFirstName} TagName={'span'} />
                    <GlowTextBody
                        className={'px-0.5 bg-background-neutral justify-center items-center rounded-0.25'}
                        color={'primary'}
                        text={loyaltyPoints}
                        fontWeight={'bold'}
                        TagName={'span'}
                    />
                </span>

                <GlowTextBody text={t('label.fidText')} TagName={'span'} />
            </span>
        </span>
    );
};

type ProductPushLoyaltyProps = PropsWithFontFamily &
    PropsWithLocale & {
        price: number;
        currency: string;
        isVipBrand: boolean;
        isChevronRight?: boolean;
    };

export const ProductPushLoyalty: Component<ProductPushLoyaltyProps> = ({
    price,
    locale,
    currency,
    fontFamily,
    isVipBrand,
    isChevronRight = false,
}) => {
    const t = useTranslations('Discover.Pdp.loyalty');

    // Retrieve user information
    const { result: userInfoResult } = useUserInfo();
    const userInfo = useMemo(() => getConnectedUserInfo(userInfoResult), [userInfoResult]);

    const loyaltyPointsPerCurrency = 1; // FIXME: get config of loyaltyPointsPerCurrencyJSON from SODA
    const loyaltyPointPerProductPrice = LoyaltyHelpers.calcPoints(price, loyaltyPointsPerCurrency);
    const loyaltyPoints = t('label.loyaltyPoints', {
        value: loyaltyPointPerProductPrice,
    });

    const customerFirstName = userInfo?.firstName ? `${userInfo.firstName},` : t('label.fidWin');
    const userCard = userInfo?.loyalty ? userInfo.loyalty.sephoraCard : UserSephoraCard.PreBuy;

    return (
        <div
            id={PRODUCT_PUSH_LOYALTY_ID}
            className={clsx(
                'flex flex-col gap-1.5 rounded-0.5',
                isVipBrand ? VIP_BORDER_CLASSES : styles.customGradientFid,
            )}
        >
            <ProductDetailInfoCTA
                menu={
                    <ProductDetailInfoModal
                        title={t('label.modalTitle')}
                        closeButtonLabel={t('action.loyaltyAlreadyMember')}
                        actionButtonLabel={t('action.joinLoyaltyProgram')}
                        actionButtonHref={buildRoute(locale, 'loyalty')}
                        renderInformation={
                            <PushLoyaltyModal
                                loyaltyPointsPerCurrency={loyaltyPointsPerCurrency}
                                loyaltyPoints={loyaltyPoints}
                                locale={locale}
                                currency={currency}
                                isVipBrand={isVipBrand}
                            />
                        }
                        contentContainerClassName={'p-1'}
                        isChevronRight={isChevronRight}
                    />
                }
                labelElement={
                    <PushLoyaltyContent
                        loyaltyPoints={loyaltyPoints}
                        userCard={userCard}
                        customerFirstName={customerFirstName}
                    />
                }
                className={'text-text-primary p-1'}
            />
        </div>
    );
};

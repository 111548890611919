import { CtaDTO } from '@headless-workspace/domain/common/client';
import { DisplayDTO } from '@headless-workspace/domain/common/server';
import { z } from 'zod';

export const NewsletterItemDTO = z.object({
    id: z.union([
        z.literal('NEWSLETTER_BANNER_OFFER'),
        z.literal('NEWSLETTER_LEGAL_MENTIONS_RIGHTS'),
        z.literal('LEGAL_MENTION_OFFER_SUBSCRIPTION'),
        z.literal('NEWSLETTER_CONFIRMATION_POPUP'),
        z.literal('NEWSLETTER_SUCCESSFUL_SUBSCRIPTION'),
    ]),
    title: z.string().optional(),
    subtitle: z.string().optional(),
    name: z.string().optional(),
    description: z.string().optional(),
    ctas: z.array(CtaDTO).optional(),
});

export type NewsletterItemDTO = z.infer<typeof NewsletterItemDTO>;

export const NewsletterDTO = z.object({
    id: z.string(),
    type: z.string(),
    display: DisplayDTO,
    details: z.object({
        items: z.array(NewsletterItemDTO),
    }),
});

export type NewsletterDTO = z.infer<typeof NewsletterDTO>;

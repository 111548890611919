import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import { UserRevalidationKey, useSafeSWR } from '@headless-workspace/domain/common/client';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import { ProductDetailValue, getProductServerAction } from '../../../../server';
import { ProductDetailsErros } from './ProductDetailsErros';

export const useProductDetails = (productId: string, vipBrands: VipBrandsValue): AsyncData<ProductDetailValue> => {
    const t = useTranslations('Discover.Pdp.productInfo.error');

    const { data, isLoading, error } = useSafeSWR(
        `${UserRevalidationKey.Products}/${productId}`,
        ServerActionName.getProductById,
        () => getProductServerAction(productId, vipBrands),
    );

    if (error) {
        return {
            isLoading,
            result: ResultFailure(ProductDetailsErros.Default, error instanceof Error ? error.message : t('default')),
        };
    }

    if (!data) {
        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};

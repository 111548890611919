import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { KeyboardEventKey } from '@headless-workspace/config';
import { MediaValue } from 'domains/common/src';
import { YoutubeScriptContext } from '../../providers';

export const YT_PLAYER_STATES: Record<string, YT.PlayerState> = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5,
};

interface ProductDetailVisualVideoProps {
    media: MediaValue;
    isDisplayed: boolean;
    onStateChange: (state: YT.PlayerState) => void;
}

export const ProductDetailVisualVideo = ({ media, isDisplayed, onStateChange }: ProductDetailVisualVideoProps) => {
    const playerRef = useRef<YT.Player>(null);
    const iframePlayerRef = useRef<HTMLIFrameElement>(null);
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const isYoutubeScriptIsLoaded = useContext(YoutubeScriptContext);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleStateChange = useCallback(
        (event: YT.OnStateChangeEvent) => {
            setIsPlaying(event.data === YT_PLAYER_STATES.PLAYING);
            onStateChange(event.data);
        },
        [onStateChange],
    );

    useEffect(() => {
        if (isYoutubeScriptIsLoaded && !playerRef.current && iframePlayerRef.current) {
            playerRef.current = new window.YT.Player(iframePlayerRef.current, {
                videoId: media.id,
                height: '100%',
                width: '100%',
                events: {
                    onReady: handleReady,
                    onStateChange: handleStateChange,
                },
            });
        }
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, [isYoutubeScriptIsLoaded, media.id]);

    const handleReady = (event: YT.PlayerEvent) => {
        setIsPlayerReady(true);
    };

    useEffect(() => {
        if (isPlayerReady && playerRef.current) {
            if (isDisplayed) {
                playerRef.current.playVideo();
            } else {
                playerRef.current.pauseVideo();
            }
        }
    }, [isDisplayed, isPlayerReady]);

    const playVideo = () => {
        if (playerRef.current) {
            playerRef.current.playVideo();
        }
    };

    const hanbleKeyDown = (keyboardEvent: React.KeyboardEvent) => {
        if (keyboardEvent.key === KeyboardEventKey.Enter || keyboardEvent.key === KeyboardEventKey.Space) {
            playVideo();
        }
    };

    return (
        <div className={'relative'}>
            <div
                tabIndex={-1}
                ref={iframePlayerRef}
                id={media.id}
                className={
                    'w-productModal tablet:w-productModalTablet desktopS:w-productModalImgDesktopS desktop:w-productModalImgDesktop aspect-1/1'
                }
                title={media.title}
            />
            {/* Overlay to allow swipe */}
            {!isPlaying && (
                <div
                    tabIndex={0}
                    onClick={playVideo}
                    className={'absolute inset-0 touch-pan-x'}
                    onKeyDown={hanbleKeyDown}
                    role="button"
                />
            )}
        </div>
    );
};

'use client';

import clsx from 'clsx';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { HTMLString } from '@headless-workspace/domain/common/server';
import { GlowTextBody, PropsWithFontFamily, typographyStyle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HtmlHelpers, StringHelpers } from '@headless-workspace/utils';
import styles from './product-info-section.module.scss';

type ProductInfoSectionProps = PropsWithFontFamily & {
    value: HTMLString;
    label: string;
};

const MAX_HEIGHT_VALUE_WITH_IMG = `${3.5 + 1.5 * 4}rem`; /* image size + 4 lines(2 <br> + 2 lines of text) */
const MAX_HEIGHT_VALUE_WITHOUT_IMG = `${1.5 * 2}rem`; /* 2 lines */

export const ProductInfoDescription: Component<ProductInfoSectionProps> = ({ value, label, fontFamily }) => {
    const hasImgLabel = HtmlHelpers.containsImgTag(value.content);
    const maxHeightValue = hasImgLabel ? MAX_HEIGHT_VALUE_WITH_IMG : MAX_HEIGHT_VALUE_WITHOUT_IMG;
    const lineClampClass = hasImgLabel ? 'line-clamp-3' : 'line-clamp-2';
    const isIphone = StringHelpers.isDevice('iPhone');

    return (
        <div id={'product-description-box'} className={'whitespace-normal w-full'} itemProp={'description'}>
            <GlowTextBody text={label} fontWeight={'bold'} fontFamily={fontFamily} />
            <InnerHTML
                style={isIphone ? undefined : { maxHeight: maxHeightValue }}
                className={clsx(
                    styles.descriptionWrapper,
                    styles.descriptionContent,
                    typographyStyle({ fontFamily }),
                    isIphone ? 'overflow-hidden max-h-productDescription' : lineClampClass,
                )}
                rawHtml={value}
            />
        </div>
    );
};

import { CatalogPingRepository, CatalogPingRepositorySpec } from '@headless-workspace/core-domain';
import {
    AppLinksBannerRepository,
    AppLinksBannerRepositorySpec,
    HolidaysRepository,
    HolidaysRepositorySpec,
    HomeSlotsRepository,
    HomeSlotsRepositorySpec,
    NewsletterConfigRepository,
    NewsletterConfigRepositorySpec,
    NewsletterSlotRepository,
    NewsletterSlotRepositorySpec,
    NotifyMeRepository,
    NotifyMeRepositorySpec,
    OmnibusInformationsRepository,
    OmnibusInformationsRepositorySpec,
    ProductDetailDeliveryInfoRepository,
    ProductDetailDeliveryInfoRepositorySpec,
    ProductsRepository,
    ProductsRepositorySpec,
    PromoStandardTextRepository,
    PromoStandardTextRepositorySpec,
    ReviewsRepository,
    ReviewsRepositorySpec,
} from '../../src';

export const NewsletterConfigRepositoryType = 'NewsletterConfigRepository';
export const HomeSlotsRepositoryType = 'HomeSlotsRepository';
export const ProductsRepositoryType = 'ProductsRepository';
export const AppLinksBannerRepositoryType = 'AppLinksBannerRepository';
export const CatalogPingRepositoryType = 'CatalogPingRepository';
export const NotifyMeRepositoryType = 'NotifyMeRepository';
export const NewsletterSlotRepositoryType = 'NewsletterSlotRepository';
export const OmnibusInformationsRepositoryType = 'OmnibusInformationsRepository';
export const HolidaysRepositoryType = 'HolidaysRepository';
export const ReviewsRepositoryType = 'ReviewsRepository';
export const ProductDetailDeliveryInfoRepositoryType = 'ProductDetailDeliveryInfoRepository';
export const PromoStandardTextRepositoryType = 'PromoStandardTextRepository';

export const deps = () => ({
    [HomeSlotsRepositoryType]: HomeSlotsRepository as new () => HomeSlotsRepositorySpec,
    [NewsletterConfigRepositoryType]: NewsletterConfigRepository as new () => NewsletterConfigRepositorySpec,
    [ProductsRepositoryType]: ProductsRepository as new () => ProductsRepositorySpec,
    [AppLinksBannerRepositoryType]: AppLinksBannerRepository as new () => AppLinksBannerRepositorySpec,
    [CatalogPingRepositoryType]: CatalogPingRepository as new () => CatalogPingRepositorySpec,
    [NotifyMeRepositoryType]: NotifyMeRepository as new () => NotifyMeRepositorySpec,
    [NewsletterSlotRepositoryType]: NewsletterSlotRepository as new () => NewsletterSlotRepositorySpec,
    [OmnibusInformationsRepositoryType]: OmnibusInformationsRepository as new () => OmnibusInformationsRepositorySpec,
    [HolidaysRepositoryType]: HolidaysRepository as new () => HolidaysRepositorySpec,
    [ReviewsRepositoryType]: ReviewsRepository as new () => ReviewsRepositorySpec,
    [ProductDetailDeliveryInfoRepositoryType]:
        ProductDetailDeliveryInfoRepository as new () => ProductDetailDeliveryInfoRepositorySpec,
    [PromoStandardTextRepositoryType]: PromoStandardTextRepository as new () => PromoStandardTextRepositorySpec,
});

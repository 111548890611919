import { ProductDetailBannerDTOTypes } from '../banner/ProductDetailBannerDTOCommon';
import { ProductDetailBannerDTO } from '../banner/ProductDetailBannerDTOTypes';

export const BannersMock: ProductDetailBannerDTO[] = [
    {
        type: ProductDetailBannerDTOTypes.Loyalty.value,
        id: '23112023_AQ_ALGOV19_HAIR',
        title: '<p>Découvrir la marque Dior</p>',
        legalMention:
            "<p>Offre strictement personnelle valable <b>jusqu’au 16/09/2024</b> inclus sur une sélection de produits signalés sur sephora.fr et l'application mobile Sepho ra France (hors frais de livraison). Offre réservée aux clients membres du programme de fidélité Sephora. Hors points rouges (voir liste sur sephora.fr), services, carte cadeaux Sephora, produits vendus au profit d'œuvres caritatives, et autres exclusions mentionnées sur sephora.fr ou l'application mobile Sephora France. Non cumulable avec toute offre ou promotion en cours. Dans la limite des stocks disponibles. Offre s'appliquant automatiquement dans votre panier. Visuel non contractuel.</p>",
        image: {
            default: '/images/placeholder.png',
            alt: 'Loyalty Banner',
        },
    },
    {
        type: ProductDetailBannerDTOTypes.Marketing.value,
        id: '22112023_AQ_ALGOV19_HAIRR',
        title: '<p>Innovation - Découvrez la nouvelle génération double sérum</p>',
        cta: {
            label: 'Découvrir',
            link: 'https://www.sephora.fr/dior/DIOR-HubPage.html',
        },
        image: {
            default: '/images/brandCorner.png',
            alt: 'Marketing Banner',
        },
    },
    {
        id: 'VAP_0334',
        type: ProductDetailBannerDTOTypes.GiftWithPurchase.value,
        title: `<p>Une crème fouettée Lala Retro 5ml offerte dès 69€ d'achats dans la marque Drunk Elephant.</p>`,
        description: '<p>Connectez-vous ou adhérez au programme de fidélité.</p>',
        legalMention: `<p>Offre strictement personnelle valable jusqu’au 16/09/2024 inclus sur une sélection de produits signalés sur sephora.fr et l'application mobile Sepho ra France (hors frais de livraison). Offre réservée aux clients membres du programme de fidélité Sephora. Hors points rouges (voir liste sur sephora.fr), services, carte cadeaux Sephora, produits vendus au profit d'œuvres caritatives, et autres exclusions mentionnées sur sephora.fr ou l'application mobile Sephora France. Non cumulable avec toute offre ou promotion en cours. Dans la limite des stocks disponibles. Offre s'appliquant automatiquement dans votre panier. Visuel non contractuel.</p>`,
    },
    {
        id: 'VAP_0335',
        type: ProductDetailBannerDTOTypes.GiftWithPurchase.value,
        title: `<p>Une miniature Good Girl Parfum 7ml offerte dès l'achat d'un parfum</p>`,
        description: '<p>Connectez-vous ou adhérez au programme de fidélité.</p>',
        legalMention: `<p>Offre valable du 21/01/2025 et jusqu'à épuisement des stocks dès l'achat d'un parfum en 50ml et plus réalisé en une seule fois dans la marque CAROLINA HERRERA sur sephora.fr et l’application mobile Sephora France (hors frais de livraison). Cumulable avec toute offre ou promotion en cours. Hors Click & Collect et livraison express par coursier. Hors services, cartes cadeaux Sephora, produits vendus au profit d'œuvres caritatives, produits et autres exclusions mentionnées en magasin ou sur sephora.fr. Offre s'appliquant automatiquement dans le panier. Produit offert non échangeable. Dans la limite des stocks disponibles. Visuel non contractuel.</p>`,
    },
];

'use client';

import { useMemo } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { ProductType } from '@headless-workspace/domain/common/client';
import {
    GlowIcon,
    GlowImage,
    GlowPalette,
    GlowSelector,
    GlowTag,
    GlowTextBody,
    Icons,
    PropsWithFontFamily,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FunctionHelpers } from '@headless-workspace/utils';
import { ProductDetailInfoCTA } from '../../../../../client';
import { CapacityFormat, ProductDetailVariantValue } from '../../../../../server';
import { ProductDetailSelectorMenu } from './ProductDetailSelectorMenu';

const VARIANT_IMAGE_SIZE = 40;

type VariantSelectorProps = PropsWithLocale &
    PropsWithFontFamily & {
        type: ProductType;
        sku: string;
        variants: ProductDetailVariantValue[];
        formats: CapacityFormat[];
        currency: string;
        enableDiscountInfo?: boolean;
        priceAlwaysBlack?: boolean;
        squaredShade: boolean;
    };

export const VariantSelector: Component<VariantSelectorProps> = ({
    type,
    sku,
    variants,
    formats,
    currency,
    enableDiscountInfo,
    priceAlwaysBlack,
    squaredShade,
    locale,
    fontFamily,
}) => {
    if (type === ProductType.PRODUCT_SET) {
        return (
            <GlowTextBody text={`Mock SELECTOR ZONE, the current SKU (variantId) is: ${sku}`} fontFamily={fontFamily} />
        );
    }

    const imgShapeClassName =
        (type === ProductType.MULTI_SKU_SHADE_UNI || type === ProductType.MULTI_SKU_SHADE_DIFF) && !squaredShade
            ? 'rounded-infinity'
            : '';

    const buttonElement = (openMenu: () => void) => {
        const hasMultipleVariants = variants.length > 1;
        const RightElement = hasMultipleVariants ? <GlowIcon Icon={Icons.ChevronRight} type={'medium'} /> : null;
        const onClick = hasMultipleVariants ? openMenu : undefined;

        // FIXME: Use the correct variant when variant selection behavior is implemented
        const { thumbnailImage: variantImage, name: variantName } = variants[0];
        return (
            <GlowSelector
                image={variantImage}
                imageClassName={imgShapeClassName}
                ImageTagName={Image}
                titleContent={{
                    label: variantName,
                    fontWeight: 'bold',
                }}
                RightElement={RightElement}
                onClick={onClick}
                fontFamily={fontFamily}
            />
        );
    };

    return (
        <ProductDetailInfoCTA
            menu={
                <ProductDetailSelectorMenu
                    type={type}
                    formats={formats}
                    variants={variants}
                    locale={locale}
                    currency={currency}
                    fontFamily={fontFamily}
                    enableDiscountInfo={enableDiscountInfo}
                    priceAlwaysBlack={priceAlwaysBlack}
                    squaredShade={squaredShade}
                    productDefaultSku={sku}
                />
            }
            buttonElement={buttonElement}
        />
    );
};

export const VariantSelectorZone: Component<VariantSelectorProps> = ({
    type,
    sku,
    variants,
    formats,
    currency,
    enableDiscountInfo,
    priceAlwaysBlack,
    squaredShade,
    locale,
    fontFamily,
}) => {
    const t = useTranslations('Discover.Pdp.information.selector');
    const imgShapeClassName =
        (type === ProductType.MULTI_SKU_SHADE_UNI || type === ProductType.MULTI_SKU_SHADE_DIFF) && !squaredShade
            ? 'rounded-infinity'
            : '';

    const Selector = useMemo(() => {
        return (
            <VariantSelector
                type={type}
                sku={sku}
                variants={variants}
                formats={formats}
                currency={currency}
                enableDiscountInfo={enableDiscountInfo}
                priceAlwaysBlack={priceAlwaysBlack}
                squaredShade={squaredShade}
                locale={locale}
                fontFamily={fontFamily}
            />
        );
    }, [
        currency,
        enableDiscountInfo,
        fontFamily,
        formats,
        locale,
        priceAlwaysBlack,
        sku,
        squaredShade,
        type,
        variants,
    ]);

    const Palette = useMemo(() => {
        if (type !== ProductType.MULTI_SKU_SHADE_UNI && type !== ProductType.MULTI_SKU_SHADE_DIFF) {
            return null;
        }
        return (
            <GlowPalette
                items={variants.map((variant) => ({
                    id: variant.id,
                    ariaLabel: t('accessibilityText.variant', {
                        variantName: variant.name,
                    }),
                    image: variant.thumbnailImage,
                }))}
                onClick={FunctionHelpers.emptyFn}
                renderItem={({ id, image }) => {
                    return (
                        <GlowImage
                            image={image}
                            imgClassName={clsx(
                                id === sku && 'border-bold border-solid border-border-selected p-paletteSelectedImg',
                                imgShapeClassName,
                            )}
                            height={VARIANT_IMAGE_SIZE}
                            width={VARIANT_IMAGE_SIZE}
                            TagName={Image}
                        />
                    );
                }}
                previousButtonAriaLabel={t('accessibilityText.prevBtn')}
                nextButtonAriaLabel={t('accessibilityText.nextBtn')}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgShapeClassName, type, variants]);

    if (type === ProductType.PRODUCT_SET || type === ProductType.MONO_SKU) {
        return Selector;
    }

    const labelMap = {
        [ProductType.MULTI_SKU_SIZE]: t('label.selectMultiSkuSize', {
            variantCount: variants.length,
        }),
        [ProductType.MULTI_SKU_SHADE_UNI]: t('label.selectMultiSkuShade', {
            variantCount: variants.length,
        }),
        [ProductType.MULTI_SKU_SHADE_DIFF]: t('label.selectMultiSkuShade', {
            variantCount: variants.length,
        }),
    };

    return (
        <div className={'flex flex-col gap-0.75'}>
            <GlowTextBody text={labelMap[type]} fontFamily={fontFamily} />
            {Selector}
            {formats.includes(CapacityFormat.Mini) && (
                <GlowTag
                    label={t('label.availableInMiniFormat')}
                    type={'neutral'}
                    size={'medium'}
                    fontFamily={fontFamily}
                />
            )}
            {Palette}
        </div>
    );
};

import { Component } from '@headless-workspace/typings';

export const NextPageSection: Component = () => {
    return (
        <section id={'product-list-next-page-content'} className={'px-1 py-1.5'}>
            <div id={'product-list-next-page'} className={'bg-background-skeleton'}>
                Next page
            </div>
        </section>
    );
};

import React from 'react';
import { getTranslations } from 'next-intl/server';
import clsx from 'clsx';
import { DefaultValueConfig } from '@headless-workspace/config';
import { InnerHTML } from '@headless-workspace/domain/common/client';
import { AsyncComponent, HTMLString, VipBrandsValue } from '@headless-workspace/domain/common/server';
import { GlowContainerSection, PropsWithClassName, PropsWithLocale } from '@headless-workspace/glow-ds';
import { CTA } from '../../../types';
import { ProductCarouselContainer } from './ProductCarouselContainer';

type HomeProductCarouselSectionProps = PropsWithClassName &
    PropsWithLocale & {
        title: HTMLString;
        cta?: CTA;
        productsIds: string[];
        vipBrands: VipBrandsValue;
    };

export const HomeProductCarouselSection: AsyncComponent<HomeProductCarouselSectionProps> = async ({
    title,
    cta,
    productsIds,
    className,
    locale,
    vipBrands,
}) => {
    if (productsIds.length === 0) {
        return null;
    }

    const t = await getTranslations('Discover.Home.carousel');

    return (
        <GlowContainerSection
            className={clsx('gap-1.5 tablet:gap-2 desktop:px-1.5', className)}
            titleContainerClassName={'px-1 desktop:px-4'}
            title={<InnerHTML rawHtml={title} />}
            button={{
                label: cta?.label ?? t('action.seeAll'),
                href: cta?.href ?? DefaultValueConfig.href,
            }}
            titleClassName={'line-clamp-2'}
        >
            <ProductCarouselContainer
                productsIds={productsIds}
                locale={locale}
                vipBrands={vipBrands}
                navigationButtonClassName={'hidden desktop:flex'}
            />
        </GlowContainerSection>
    );
};

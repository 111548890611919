'use client';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { PRODUCT_INFO_QUICK_VIEW_MENU_ID } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { UIContext } from '@headless-workspace/core-ui';
import { VipBrandsValue } from '@headless-workspace/domain/common/server';
import {
    GlowButton,
    GlowClickable,
    GlowDivider,
    GlowIcon,
    GlowTextBody,
    GlowTitle,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FunctionHelpers } from '@headless-workspace/utils';
import {
    ProductDetailPricesContainer,
    ProductPushLoyalty,
    ProductVisualMobile,
    useProductDeliveryBusinessRules,
    VariantSelectorList,
    useProductDetails,
    usePromotionInfo,
    SelectorAddToBasketButton,
} from '../../../client';
import { useProductDetailsUtils } from '../productDetail/useProductDetailsUtils';
import { QuickViewProductMenuSkeleton } from './QuickViewProductMenuSkeleton';

type QuickViewProductMenuProps = PropsWithLocale & {
    productId: string;
    productSku: string;
    vipBrands: VipBrandsValue;
};

export const QuickViewProductMenu: Component<QuickViewProductMenuProps> = ({ productSku, vipBrands, locale }) => {
    const {
        menuModal: { close: closeMenu },
    } = useContext(UIContext);

    const t = useTranslations('Discover.Pdp');

    const [selectedSku, setSelectedSku] = useState<string>(productSku);

    const { isLoading: isProductDetailsInfoLoading, result: productDetailsInfoResult } = useProductDetails(
        selectedSku,
        vipBrands,
    );

    const { isProductDetailsLoading, product } = useMemo(
        () => ({
            isProductDetailsLoading: isProductDetailsInfoLoading,
            product: unwrapResultSuccess(productDetailsInfoResult),
        }),
        [isProductDetailsInfoLoading, productDetailsInfoResult],
    );

    const { productDeliveryInfoData, isNotifyMeEnabledData } = useProductDeliveryBusinessRules({
        locale,
        stock: product?.stockLevel ?? 0,
        sku: selectedSku,
    });

    const { productSelectionTitle, getStockInformation } = useProductDetailsUtils(product?.type);

    const { result: promotionInfoResult } = usePromotionInfo(product?.promotion);

    useEffect(() => {
        setSelectedSku(productSku);
    }, [productSku]);

    const closeProductInformationModal = useCallback(() => {
        closeMenu(PRODUCT_INFO_QUICK_VIEW_MENU_ID);
    }, [closeMenu]);

    if (!(product && !isProductDetailsLoading)) {
        return <QuickViewProductMenuSkeleton />;
    }

    const fontFamily = product?.isArialFont ? 'arial' : 'brand';

    const discountPercentageLabel = t('information.price.label.discountPercentage', {
        discountPercentage: product?.discountPercentage,
    });

    const onClickUpdateSku = (sku: string) => {
        setSelectedSku(sku);
    };

    return (
        <div className={'h-fullViewport w-fullViewport tablet:w-sideMenuTablet flex flex-col'}>
            <div
                className={
                    'flex flex-row justify-between items-center p-1 gap-1 border-b-medium border-b-border-subtle border-solid h-headerFixedNavDesktop'
                }
            >
                <GlowTitle text={productSelectionTitle} fontSize={'title4'} fontFamily={fontFamily} TagName={'h4'} />
                <GlowClickable
                    onClick={closeProductInformationModal}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} className={'text-text-primary'} />,
                    }}
                />
            </div>
            <div className={'flex flex-col p-1 gap-1.5 h-quickViewProductMenuContainer overflow-x-hidden'}>
                <ProductVisualMobile
                    medias={product.medias}
                    className={'w-[21.438rem] tablet:w-[27.5rem]'}
                    visualClassName={'w-[16.438rem] tablet:w-[22.5rem]'}
                    onProductVisualClick={FunctionHelpers.nullFn}
                    prevBtnAriaLabel={t('carousel.ariaLabel.prevBtn')}
                    nextBtnAriaLabel={t('carousel.ariaLabel.nextBtn')}
                    thumbArialLabel={t('carousel.ariaLabel.thumb')}
                />
                <div>
                    <ProductDetailPricesContainer
                        price={product.price}
                        priorPrice={product.priorPrice}
                        originalPrice={product.originalPrice}
                        pricePerUnit={product.pricePerUnit}
                        currency={product.currency}
                        locale={locale}
                        fontFamily={fontFamily}
                        promotion={product.promotion}
                        discountPercentageLabel={discountPercentageLabel}
                        priceAlwaysBlack={product.priceAlwaysBlack}
                        isVipBrand={product.isVipBrand}
                        promotionInformation={unwrapResultSuccess(promotionInfoResult)}
                    />
                </div>
                <GlowTextBody
                    text={t('information.selector.modal.label.products', {
                        variantCount: product.variants.length,
                    })}
                    fontWeight={'bold'}
                />
                <VariantSelectorList
                    locale={locale}
                    type={product.type}
                    variants={product.variants}
                    squaredShade={product.squaredShade}
                    currency={product.currency}
                    fontFamily={fontFamily}
                    productDefaultSku={selectedSku}
                    onUpdateSku={onClickUpdateSku}
                    getStockInformation={getStockInformation}
                />
                <ProductPushLoyalty
                    fontFamily={fontFamily}
                    locale={locale}
                    price={product.price}
                    currency={product.currency}
                    isVipBrand={product.isVipBrand}
                    isChevronRight={true}
                />
            </div>

            <div className={'mt-auto'}>
                <GlowDivider />
                <div className={'p-1 flex flex-col w-full gap-0.5 justify-center'}>
                    <SelectorAddToBasketButton
                        locale={locale}
                        sku={selectedSku}
                        productDeliveryInfoData={productDeliveryInfoData}
                        isNotifyMeEnabledData={isNotifyMeEnabledData}
                        vipBrands={vipBrands}
                        hasShortLabel={false}
                        fontFamily={fontFamily}
                    />
                    <GlowButton
                        variant={'secondary'}
                        onClick={FunctionHelpers.emptyFn}
                        className={'w-full'}
                        label={t('productInfo.productTab.action.discover')}
                        fontFamily={fontFamily}
                    />
                </div>
            </div>
        </div>
    );
};

'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useLocalityDetails, useStoreAutocomplete, useUserGeolocation } from '@headless-workspace/domain/common/client';
import { getStoresServerAction, StoreInfo } from '@headless-workspace/domain/common/server';
import {
    GlowDropDownSearch,
    GlowInlineToast,
    GlowSegmentedPicker,
    Icons,
    PropsWithLocale,
    ToastType,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { DateHelpers, FormatHelpers } from '@headless-workspace/utils';
import { useDebounceCallback } from 'usehooks-ts';
import { StoreSliderDisplay } from './StoreSliderDisplay';

export type StoresDeliveryContainerProps = PropsWithLocale & {
    productId: string;
    selectedStore?: StoreInfo;
    favoriteStoreId?: string;
    onSelectedStoreChange: (store: StoreInfo) => void;
};

export enum DisplayOption {
    Map = 'map',
    List = 'list',
}

const SEARCH_INPUT_DEBOUNCE_MS = 500;
const STORES_DELIVERY_ID = 'stores-delivery';

export const StoresDeliveryContainer: Component<StoresDeliveryContainerProps> = ({
    locale,
    productId,
    selectedStore,
    favoriteStoreId = '',
    onSelectedStoreChange,
}) => {
    const discoverTranslation = useTranslations('Discover.Pdp.deliveryZone.stores');
    const commonTranslation = useTranslations('Common.pickerOptions');

    const [selectedDisplayOption, setSelectedDisplayOption] = useState<DisplayOption>(DisplayOption.Map);
    const [stores, setStores] = useState<StoreInfo[]>([]);
    const [currentTime, setCurrentTime] = useState<string | undefined>();
    const [storesFetched, setStoresFetched] = useState<boolean>(false);
    const [highlightedStore, setHighlightedStore] = useState<StoreInfo | undefined>(selectedStore);

    const { localityDetails, getLocalityDetails } = useLocalityDetails();
    const { userPosition, userAddress, getCurrentPosition } = useUserGeolocation();

    // TODO: Replace all this block with Autocomplete component's behavior
    const { localitiesResults, searchLocalities, isLoading: isStoreAutocompleteLoading } = useStoreAutocomplete();

    const fetchStores = useCallback(
        (latitude: number, longitude: number) => {
            setStoresFetched(false);
            getStoresServerAction([productId], latitude, longitude).then((result) => {
                if (result.type === 'success') {
                    setStores(result.data);
                    setStoresFetched(true);
                    const date = new Date();
                    setCurrentTime(DateHelpers.formatDateTimeWithHourMinute(locale, discoverTranslation('at'), date));
                } else if (result.type === 'failure') {
                    // TODO: Better error handling with toasts
                    console.error('Error fetching stores', result.meta);
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locale, productId],
    );

    // Fetch stores on load with user position
    useEffect(() => {
        if (userPosition) {
            fetchStores(userPosition.coords.latitude, userPosition.coords.longitude);
        }
    }, [fetchStores, userPosition]);

    // Fetch stores when locality details are available (after locality click)
    useEffect(() => {
        localityDetails &&
            fetchStores(localityDetails.geometry.location.latitude, localityDetails.geometry.location.longitude);
    }, [fetchStores, localityDetails]);

    const pickerOptions = useMemo(
        () =>
            Array.from(
                Object.keys(DisplayOption).map((option) => {
                    const displayOptionValue = DisplayOption[option as keyof typeof DisplayOption];
                    return {
                        id: displayOptionValue,
                        label: commonTranslation(`${displayOptionValue}`),
                    };
                }),
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleDisplayOptionChange = (option: DisplayOption) => {
        setSelectedDisplayOption(option);
    };

    const handleSearchLocationChange = useDebounceCallback(async (input: string) => {
        await searchLocalities(input);
    }, SEARCH_INPUT_DEBOUNCE_MS);

    const onSearchRightIconSubmit = (setPositionCallback: (input: string) => void) => {
        getCurrentPosition(setPositionCallback);
    };

    return (
        <>
            <section className={'flex flex-col p-1 gap-1'}>
                <GlowDropDownSearch
                    id={STORES_DELIVERY_ID}
                    defaultValue={
                        userAddress
                            ? FormatHelpers.formatAddress(
                                  userAddress.postalCode,
                                  userAddress.locality,
                                  userAddress.country,
                              )
                            : ''
                    }
                    optionsList={localitiesResults ?? []}
                    title={discoverTranslation('searchLabel')}
                    onSearchChange={handleSearchLocationChange}
                    ariaLabelInput={discoverTranslation('searchLabel')}
                    helperLabel={discoverTranslation('searchLabel')}
                    rightSearchInputIcon={Icons.Location}
                    isLoading={isStoreAutocompleteLoading}
                    onSearchRightIconSubmit={onSearchRightIconSubmit}
                    handleOptionClick={(option) => getLocalityDetails(option.id)}
                />
                {!storesFetched && (
                    <GlowInlineToast
                        hasCloseBtn
                        label={{
                            text: discoverTranslation('searchForAnAddressOrActivateGeolocation'),
                        }}
                        toastType={ToastType.Info}
                    />
                )}
                <GlowSegmentedPicker
                    options={pickerOptions}
                    value={selectedDisplayOption}
                    onChange={handleDisplayOptionChange}
                />
            </section>
            <section className={'p-1 flex flex-col gap-1 h-StoresDeliveryInfoModalContainer overflow-x-auto'}>
                <StoreSliderDisplay
                    stores={stores}
                    fetched={storesFetched}
                    currentTime={currentTime ?? ''}
                    displayOption={selectedDisplayOption}
                    favoriteStoreId={favoriteStoreId}
                    setHighlightedStore={setHighlightedStore}
                    highlightedStoreId={highlightedStore?.id}
                    userPosition={userPosition}
                />
            </section>
        </>
    );
};

'use client';

import { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { ProductType } from '@headless-workspace/domain/common/client';
import { GlowPills, GlowTextBody, Pill, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component, NoEmptyArray } from '@headless-workspace/typings';
import { ProductDetailInfoModal, VariantSelectorList } from '../../../../../client';
import { CapacityFormat, ProductDetailVariantValue } from '../../../../../server';
import { useProductDetailsUtils } from '../../useProductDetailsUtils';

const FILTER_ALL_FORMATS_ID = 'all-formats';

type ProductDetailSelectorMenuProps = PropsWithLocale &
    PropsWithFontFamily & {
        type: ProductType;

        formats: CapacityFormat[];
        variants: ProductDetailVariantValue[];
        productDefaultSku: string;
        priceAlwaysBlack?: boolean;
        enableDiscountInfo?: boolean;
        currency: string;
        squaredShade?: boolean;
    };

export const ProductDetailSelectorMenu: Component<ProductDetailSelectorMenuProps> = ({
    type,
    formats,
    variants,
    fontFamily,
    priceAlwaysBlack,
    currency,
    squaredShade = false,
    enableDiscountInfo,
    productDefaultSku,
    locale,
}) => {
    const t = useTranslations('Discover.Pdp.information.selector');
    const { productSelectionTitle, getStockInformation } = useProductDetailsUtils(type);
    const [filteredVariants, setFilteredVariants] = useState<ProductDetailVariantValue[]>(variants);

    const pills: NoEmptyArray<Pill> = useMemo(() => {
        const allFormatsPill = { id: FILTER_ALL_FORMATS_ID, label: t('modal.action.allFormats') };
        const formatPills = formats.map((format) => ({
            id: format.toString(),
            label: format.toString(),
        }));
        return [allFormatsPill, ...formatPills];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formats]);

    const onPillSelected = useCallback(
        (selectedPillId: string) => {
            if (selectedPillId === FILTER_ALL_FORMATS_ID) {
                setFilteredVariants(variants);
            } else {
                setFilteredVariants(variants.filter((variant) => variant.format === selectedPillId));
            }
        },
        [variants],
    );

    return (
        <ProductDetailInfoModal
            fontFamily={fontFamily}
            title={productSelectionTitle}
            renderInformation={
                <div className={'flex flex-col gap-1.5'}>
                    {formats.length > 1 && (
                        <div className={'flex flex-col gap-1'}>
                            <GlowTextBody
                                text={t('modal.label.formats', {
                                    formatCount: formats.length + 1,
                                })}
                                fontWeight={'bold'}
                                fontFamily={fontFamily}
                            />
                            <GlowPills
                                type={'radio'}
                                contents={pills}
                                defaultSelected={FILTER_ALL_FORMATS_ID}
                                onChange={onPillSelected}
                                className={'flex flex-row flex-wrap gap-0.5'}
                                fontFamily={fontFamily}
                            />
                        </div>
                    )}
                    <div className={'flex flex-col gap-1'}>
                        <GlowTextBody
                            text={t('modal.label.products', {
                                variantCount: filteredVariants.length,
                            })}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                        />
                        <VariantSelectorList
                            locale={locale}
                            type={type}
                            variants={filteredVariants}
                            squaredShade={squaredShade}
                            currency={currency}
                            fontFamily={fontFamily}
                            productDefaultSku={productDefaultSku}
                            getStockInformation={getStockInformation}
                            enableDiscountInfo={enableDiscountInfo}
                            priceAlwaysBlack={priceAlwaysBlack}
                        />
                    </div>
                </div>
            }
            contentContainerClassName={'p-1 overflow-y-auto'}
        />
    );
};

import { NextPageSection } from './nextPage';
import { Component } from '@headless-workspace/typings';
import { FilterSection } from './filters';
import { GridSection } from './grid';
import { ResultSection } from './result';

export const ProductListContentSection: Component = () => {
    return (
        <section id={'product-list-content'} className={'flex flex-row'}>
            <FilterSection />
            <div className={'flex flex-col w-full'}>
                <ResultSection />
                <GridSection />
                <NextPageSection />
            </div>
        </section>
    );
};

import { Locale } from '@headless-workspace/config';
import {
    Repository,
    Result,
    ResultEmpty,
    ResultFailureFromApiError,
    ResultSuccess,
} from '@headless-workspace/core-domain';
import {
    AuthorizationStrategy,
    memoryCacheAuthStrategy,
    memoryCacheQueryParamStrategy,
    memoryCacheRefreshTokenStrategy,
    QueryParamStrategy,
    RefreshTokenStrategy,
} from '@headless-workspace/data';
import { Scope, VipBrandsValue } from '@headless-workspace/domain/common/server';
import { DecoderHelpers } from '@headless-workspace/utils';
import {
    mapProductDetail,
    ProductDetailContainerDTO,
    ProductDetailValue,
    ProductRecommendationIdsValue,
} from '../productDetail';
import { ProductRecommendationIdsDTO } from '../productDetail/productRecommendations/ProductRecommendationIdsDTO';
import { TileProductDTO, TileProductsContainerDTO } from './TileProductDTO';
import { mapTileProduct, TileProductValue } from './TileProductValue';

export interface ProductsRepositorySpec {
    getProducts(
        locale: Locale,
        ids: string[],
        vipBrands: VipBrandsValue,
        isPreviewable?: boolean,
    ): Promise<Result<TileProductValue[]>>;

    getProductById(
        locale: Locale,
        id: string,
        vipBrands: VipBrandsValue,
        isPreviewable?: boolean,
    ): Promise<Result<ProductDetailValue>>;

    getProductRecommendationIds(
        locale: Locale,
        id: string,
        vipBrands: VipBrandsValue,
    ): Promise<Result<ProductRecommendationIdsValue>>;
}

const PRODUCTS_SEPARATOR = ';';

export class ProductsRepository extends Repository implements ProductsRepositorySpec {
    readonly path = '/catalog/v1';

    constructor(
        serverAuthStrategy: Partial<AuthorizationStrategy> = memoryCacheAuthStrategy,
        serverRefreshTokenStrategy: Partial<RefreshTokenStrategy> = memoryCacheRefreshTokenStrategy,
        serverQueryParamStrategy: Partial<QueryParamStrategy> = memoryCacheQueryParamStrategy,
    ) {
        super(serverAuthStrategy, serverRefreshTokenStrategy, serverQueryParamStrategy);
    }

    async getProducts(
        locale: Locale,
        ids: string[],
        vipBrands: VipBrandsValue,
        isPreviewable = false,
    ): Promise<Result<TileProductValue[]>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/products`, {
                ids: ids.join(PRODUCTS_SEPARATOR),
                locale,
                isPreviewable,
            });

            const productsArray = TileProductsContainerDTO.parse(response);
            const products = DecoderHelpers.safeParseArray(productsArray.products, TileProductDTO);

            if (products.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(products.map((product) => mapTileProduct(product, vipBrands, locale)));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async getProductById(
        locale: string,
        id: string,
        vipBrands: VipBrandsValue,
        isPreviewable = false,
    ): Promise<Result<ProductDetailValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/products/${id}`, {
                scope: Scope.ProductDetailPage,
                locale,
                isPreviewable,
            });

            const product = ProductDetailContainerDTO.parse(response);

            return ResultSuccess(mapProductDetail(product.product, vipBrands));
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }

    async getProductRecommendationIds(locale: Locale, id: string): Promise<Result<ProductRecommendationIdsValue>> {
        try {
            const response = await this.datasource.getResource(`${this.path}/products/${id}/recommendations`, {
                locale,
            });

            const productRecommendationIds = ProductRecommendationIdsDTO.parse(response);

            if (productRecommendationIds.length === 0) {
                return ResultEmpty();
            }

            return ResultSuccess(productRecommendationIds);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailureFromApiError(apiError, { locale });
        }
    }
}

'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { ProductType } from '@headless-workspace/domain/common/client';
import { GlowPrice, GlowSelector, PropsWithFontFamily, PropsWithLocale } from '@headless-workspace/glow-ds';
import { StringHelpers } from '@headless-workspace/utils';
import { ProductDetailVariantValue } from '../../../../../server';
import { ChipColor } from '../../delivery';

type VariantSelectorListProps = PropsWithFontFamily &
    PropsWithLocale & {
        type: ProductType;
        variants: ProductDetailVariantValue[];
        squaredShade: boolean;
        getStockInformation: (isAvailable: boolean) => {
            label: string;
            chipColor: ChipColor;
        };
        onUpdateSku?: (id: string) => void;
        priceAlwaysBlack?: boolean;
        enableDiscountInfo?: boolean;
        currency: string;
        productDefaultSku: string;
    };

export const VariantSelectorList = ({
    type,
    variants,
    productDefaultSku,
    squaredShade,
    priceAlwaysBlack,
    enableDiscountInfo,
    currency,
    locale,
    fontFamily,
    onUpdateSku,
    getStockInformation,
}: VariantSelectorListProps) => {
    const t = useTranslations('Discover.Pdp.information.selector');

    const [selectedSku, setSelectedSku] = useState<string>(productDefaultSku);

    if (!type) {
        return null;
    }

    const imgShapeClassName =
        (type === ProductType.MULTI_SKU_SHADE_UNI || type === ProductType.MULTI_SKU_SHADE_DIFF) && !squaredShade
            ? 'rounded-infinity'
            : '';

    const onVariantSelected = (sku: string) => () => {
        onUpdateSku?.(sku);
        setSelectedSku(sku);
    };

    return (
        <ul className={'flex flex-col gap-0.5'}>
            {variants.map((variant) => (
                <li
                    key={variant.id}
                    className={clsx(
                        'flex flex-row justify-between',
                        variant.id === selectedSku && 'outline outline-bold outline-border-brand rounded-0.5',
                    )}
                >
                    <GlowSelector
                        image={variant.thumbnailImage}
                        ImageTagName={Image}
                        imageClassName={imgShapeClassName}
                        titleContent={{
                            label: variant.name,
                            fontWeight: 'medium',
                        }}
                        chipWithText={getStockInformation(variant.isAvailable)}
                        RightElement={
                            <GlowPrice
                                price={
                                    variant.price > 0
                                        ? StringHelpers.formatPrice(variant.price, currency, locale)
                                        : undefined
                                }
                                priceBeforeDiscount={
                                    variant.priceBeforeDiscount
                                        ? StringHelpers.formatPrice(variant.priceBeforeDiscount, currency, locale)
                                        : undefined
                                }
                                discountPercentageLabel={t('modal.label.discountPercentage', {
                                    discountPercentage: variant.discountPercentage,
                                })}
                                priceAlwaysBlack={priceAlwaysBlack}
                                enableDiscountInfo={enableDiscountInfo}
                                fontFamily={fontFamily}
                                className={'items-end'}
                            />
                        }
                        fontFamily={fontFamily}
                        onClick={onVariantSelected(variant.id)}
                    />
                </li>
            ))}
        </ul>
    );
};

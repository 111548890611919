import { useTranslations } from 'next-intl';
import { GlowIcon, GlowTextBody, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

export const NoStoreFound: Component = () => {
    const t = useTranslations('Discover.Pdp.deliveryZone.stores.error.noStoreFound');
    return (
        <div>
            <GlowIcon Icon={Icons.ErrorSearch} type={'large'} />
            <GlowTextBody text={t('title')} fontWeight={'bold'} />
            <GlowTextBody text={t('description')} />
        </div>
    );
};

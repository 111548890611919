'use client';

import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { unwrapResultSuccess } from '@headless-workspace/core-domain';
import { useUserInfo } from '@headless-workspace/domain/common/client';
import { isGuestUserInfo } from '@headless-workspace/domain/common/server';
import {
    GlowButton,
    GlowCheckbox,
    GlowClickable,
    GlowProgressBar,
    GlowTextBody,
    PropsWithLocale,
    Target,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FunctionHelpers } from '@headless-workspace/utils';
import { ProductReview, RatingFilterData, RatingsAndReviewsFilter, ReviewSortOption } from '../../../../../src';
import { ReviewCardListItem } from './ReviewCardListItem';
import { ReviewScoreFilter } from './ReviewScoreFilter';
import { useFilteredReviews } from './useFilteredReviews';

type RatingsAndReviewsContentProps = PropsWithLocale & {
    reviewCount: number;
    baseReviews: ProductReview[];
    ratingFilter: RatingFilterData[];
    rating: number;
    maxRating: number;
    sortingOption: ReviewSortOption[];
    productId: string;
};

const PRODUCT_DETAIL_REVIEWS = 'product-detail-reviews';
const PRODUCT_DETAIL_RATINGS = 'product-detail-ratings';
const PRODUCT_DETAIL_RATINGS_AND_REVIEWS_CONTENT = 'product-detail-ratings-and-reviews-content';
const RATING_ALL = 'rating-all';

export const RatingsAndReviewsContent: Component<RatingsAndReviewsContentProps> = ({
    reviewCount,
    baseReviews,
    locale,
    rating,
    ratingFilter,
    sortingOption,
    maxRating,
    productId,
}) => {
    const t = useTranslations('Discover.Pdp.ratingAndReviews');
    const { result: userInfoResult } = useUserInfo();
    const isGuestUser = isGuestUserInfo(unwrapResultSuccess(userInfoResult));

    const {
        onRatingChange,
        onOptionUpdate,
        onResetSelectedRatings,
        onSeeMoreReviewsClick,
        selectedRatings,
        displayedReviews,
        reviews,
        isMutating,
    } = useFilteredReviews({
        baseReviews,
        reviewCount,
        productId,
    });

    // FIXME: replace the emptyFn with the right action in FHEAD-234
    // FIXME: use isLoading return by useUserInfo to show a loading state of the button
    const giveMyOpinionButtonAction = isGuestUser
        ? { href: buildRoute(locale, 'connection') }
        : { onClick: FunctionHelpers.emptyFn };

    return (
        <>
            <RatingsAndReviewsFilter
                reviewCount={reviewCount}
                rating={rating}
                maxRating={maxRating}
                sortingOption={sortingOption}
                onValueUpdate={onOptionUpdate}
            />
            <section
                id={PRODUCT_DETAIL_RATINGS_AND_REVIEWS_CONTENT}
                className={'flex flex-col tablet:flex-row gap-2.5'}
            >
                <div id={PRODUCT_DETAIL_RATINGS} className={'tablet:flex-1 flex flex-col gap-2.5'}>
                    <div className={'flex flex-col gap-1'}>
                        <div className={'flex gap-1 items-center justify-center'}>
                            <div className={'flex gap-0.25 items-center justify-center'}>
                                <GlowCheckbox
                                    id={RATING_ALL}
                                    onChange={onResetSelectedRatings}
                                    checked={selectedRatings.length <= 0}
                                />
                                <GlowTextBody text={t('label.all', { reviewCount })} className="w-maxContent" />
                            </div>
                            <GlowProgressBar value={100} />
                        </div>

                        <ReviewScoreFilter
                            ratingFilter={ratingFilter}
                            onCheckboxChange={onRatingChange}
                            selectedRatings={selectedRatings}
                        />
                    </div>

                    <div className={'flex flex-col gap-0.5'}>
                        {reviewCount > 0 && (
                            <GlowButton label={t('action.giveMyOpinion')} {...giveMyOpinionButtonAction} />
                        )}
                        <GlowClickable
                            content={{
                                labelElement: <GlowTextBody text={t('action.reviewConditions')} />,
                            }}
                            href={buildRoute(locale, 'reviewConditions')}
                            className={'text-underline'}
                            target={Target.Blank}
                        />
                    </div>
                </div>
                <div id={PRODUCT_DETAIL_REVIEWS} className={'tablet:flex-1'}>
                    {reviewCount > 0 ? (
                        <>
                            <ul className={'flex flex-col gap-1.5'}>
                                {reviews.map((review, index) => (
                                    <ReviewCardListItem
                                        key={review.id}
                                        // FIXME: adapt this in FHEAD-235
                                        className={clsx(index >= displayedReviews && 'hidden')}
                                        {...review}
                                        locale={locale}
                                    />
                                ))}
                            </ul>
                            <div className={'px-1 py-1.5 flex flex-col items-center gap-1'}>
                                <div className={'flex flex-col items-center gap-0.5 w-reviewCounter'}>
                                    <GlowProgressBar value={displayedReviews} max={reviewCount} />
                                    <GlowTextBody
                                        text={t('label.reviewCounter', {
                                            current: displayedReviews,
                                            total: reviewCount,
                                        })}
                                        className={'w-fit'}
                                    />
                                </div>
                                <GlowButton
                                    dataTestId={'see-more-reviews-button'}
                                    label={t('action.seeMoreReviews')}
                                    onClick={onSeeMoreReviewsClick}
                                    className={'self-stretch desktopS:self-auto'}
                                    variant={'secondary'}
                                    isSpinnerActive={isMutating}
                                />
                            </div>
                        </>
                    ) : (
                        <div
                            className={
                                'flex flex-col justify-center items-center gap-0.5 px-1.5 py-2 border-solid border-medium border-border-primary rounded-0.5'
                            }
                        >
                            <GlowTextBody text={t('label.noReviews')} />
                            <GlowButton
                                label={t('action.giveMyOpinion')}
                                {...giveMyOpinionButtonAction}
                                className={'w-full'}
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};
